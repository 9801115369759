/* Fonts imported  */

@font-face {
  font-family: "adobe-arabic";
  src: local("adobe-arabic"),
    url("../../fonts/AdobeArabic-Regular.woff") format('woff');
  font-weight: normal;
}

@font-face {
  font-family: "adobe-arabic";
  src: local("adobe-arabic"),
    url("../../fonts/AdobeArabic-Regular.woff2") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "adobe-arabic";
  src: local("adobe-arabic"),
    url("../../fonts/AdobeArabic-Bold.woff") format('woff');
  font-weight: bold;
}

@font-face {
  font-family: "adobe-arabic";
  src: local("adobe-arabic"),
    url("../../fonts/AdobeArabic-Bold.woff2") format("woff2");
  font-weight: bold;
}

@font-face {
  font-style: normal;
  font-family: kurdish;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  src: local("kurdish"),
  url("../../fonts/Rabar_022.woff") format("woff")
}

@font-face {
  font-style: normal;
  font-family: kurdish;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  src: local("kurdish"),
  url("../../fonts/Rabar_021.woff") format("woff")
}

@font-face {
  font-family: kurdishBold;
  src: url("../../fonts/Rabar_021.ttf")
}

/* Fonts imported end  */




/* Main Start */

:root {
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #495057;
  --text-color-secondary: #6c757d;
  --primary-color: rgb(85, 73, 148);
  --primary-color-text: #ffffff;
  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f5f5f5;
  --surface-200: #eeeeee;
  --surface-300: #e0e0e0;
  --surface-400: #bdbdbd;
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --gray-50: #fafafa;
  --gray-100: #f5f5f5;
  --gray-200: #eeeeee;
  --gray-300: #e0e0e0;
  --gray-400: #bdbdbd;
  --gray-500: #9e9e9e;
  --gray-600: #757575;
  --gray-700: #616161;
  --gray-800: #424242;
  --gray-900: #212121;
  --content-padding: 1.25rem;
  --inline-spacing: 0.5rem;
  --border-radius: 6px;
  --surface-ground: #eff3f8;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dfe7ef;
  --surface-hover: #f6f9fc;
  --focus-ring: 0 0 0 0.2rem #009eac54;
  --maskbg: rgba(0, 0, 0, 0.4);
  --blue: rgb(85, 73, 148);
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #ff1400;
  --orange: #fd7e14;
  --yellow: #f57800;
  --green: rgb(85, 73, 148);
  --teal: #20c997;
  --cyan: #51aa34;
  --white: #fff;
  --gray: #888;
  --gray-dark: #333;
  --default: #00343b;
  --primary: rgb(85, 73, 148);
  --secondary: #fff;
  --success: #51aa34;
  --info: #2563eb;
  --warning: #2a2d22;
  --danger: #ff1400;
  --light: #fff;
  --dark: #222;
  --blue-50: #f5f9ff;
  --blue-100: #d0e1fd;
  --blue-200: #abc9fb;
  --blue-300: #85b2f9;
  --blue-400: #609af8;
  --blue-500: #3b82f6;
  --blue-600: #326fd1;
  --blue-700: #295bac;
  --blue-800: #204887;
  --blue-900: #183462;
  --green-50: #f4fcf7;
  --green-100: #caf1d8;
  --green-200: #a0e6ba;
  --green-300: #76db9b;
  --green-400: #4cd07d;
  --green-500: #22c55e;
  --green-600: #1da750;
  --green-700: #188a42;
  --green-800: #136c34;
  --green-900: #0e4f26;
  --yellow-50: #fefbf3;
  --yellow-100: #faedc4;
  --yellow-200: #f6de95;
  --yellow-300: #f2d066;
  --yellow-400: #eec137;
  --yellow-500: #eab308;
  --yellow-600: #c79807;
  --yellow-700: #a47d06;
  --yellow-800: #816204;
  --yellow-900: #5e4803;
  --cyan-50: #f3fbfd;
  --cyan-100: #c3edf5;
  --cyan-200: #94e0ed;
  --cyan-300: #65d2e4;
  --cyan-400: #35c4dc;
  --cyan-500: #06b6d4;
  --cyan-600: #059bb4;
  --cyan-700: #047f94;
  --cyan-800: #036475;
  --cyan-900: #024955;
  --pink-50: #fef6fa;
  --pink-100: #fad3e7;
  --pink-200: #f7b0d3;
  --pink-300: #f38ec0;
  --pink-400: #f06bac;
  --pink-500: #ec4899;
  --pink-600: #c93d82;
  --pink-700: #a5326b;
  --pink-800: #822854;
  --pink-900: #5e1d3d;
  --indigo-50: #f7f7fe;
  --indigo-100: #dadafc;
  --indigo-200: #bcbdf9;
  --indigo-300: #9ea0f6;
  --indigo-400: #8183f4;
  --indigo-500: #6366f1;
  --indigo-600: #5457cd;
  --indigo-700: #4547a9;
  --indigo-800: #363885;
  --indigo-900: #282960;
  --teal-50: #f3fbfb;
  --teal-100: #c7eeea;
  --teal-200: #9ae0d9;
  --teal-300: #6dd3c8;
  --teal-400: #41c5b7;
  --teal-500: #14b8a6;
  --teal-600: #119c8d;
  --teal-700: #0e8174;
  --teal-800: #0b655b;
  --teal-900: #084a42;
  --orange-50: #fff8f3;
  --orange-100: #feddc7;
  --orange-200: #fcc39b;
  --orange-300: #fba86f;
  --orange-400: #fa8e42;
  --orange-500: #f97316;
  --orange-600: #d46213;
  --orange-700: #ae510f;
  --orange-800: #893f0c;
  --orange-900: #642e09;
  --bluegray-50: #f7f8f9;
  --bluegray-100: #dadee3;
  --bluegray-200: #bcc3cd;
  --bluegray-300: #9fa9b7;
  --bluegray-400: #818ea1;
  --bluegray-500: #64748b;
  --bluegray-600: #556376;
  --bluegray-700: #465161;
  --bluegray-800: #37404c;
  --bluegray-900: #282e38;
  --purple-50: #fbf7ff;
  --purple-100: #ead6fd;
  --purple-200: #dab6fc;
  --purple-300: #c996fa;
  --purple-400: #b975f9;
  --purple-500: #a855f7;
  --purple-600: #8f48d2;
  --purple-700: #763cad;
  --purple-800: #5c2f88;
  --purple-900: #432263;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}

.pi {
  font-size: 1rem;
}

.bigFontSize .p-button {
  font-size: 32px !important;
}

.bigFontSize .p-component {
  font-size: 32px !important;
}

html,
.rootDiv {
  height: 100%;
}

.sectionSeparator {
  margin-bottom: 24px;
}

.buttonsHomeWrap button.p-button.p-component.p-button-raised {
  margin-right: 2%;
}

.col-4.text-right.localeWrap .p-selectbutton.p-buttonset.p-component {
  width: 100px;
}

.accessabilityIconWrap {
  width: 40px;
}

.p-overlaypanel-content button.p-button.p-component .pi {
  padding-right: 10px;
}

.p-overlaypanel-content {
  display: flex;
  flex-direction: column;
}

button.p-button.p-component.p-button-rounded.p-button-text.btnAccessabilityIcon.p-button-icon-only:hover {
  background: transparent;
}

.splash-screen {
  height: 100%;
  width: 100%;
  background-color: #b9b9b9a1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid .login {
  display: flex;
  flex-direction: column;
}

.grid.homeLinks {
  display: flex;
  flex-wrap: nowrap;
}

.grid .logButtons {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
  justify-content: center;
}

.grid.homepic1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.grid.accRecovery {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.grid.login.linkButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.grid.secondaryButtonsLogin {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.grid.loginColumn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.grid.summaryCol {
  border-bottom: 1px solid rgb(85, 73, 148);
  margin-bottom: 8px;
}

.grid.IDsummary {
  border-bottom: 1px solid rgb(85, 73, 148);
  margin-bottom: 8px;
}

.grid.homepic2 {
  display: flex;
  align-items: flex-end;
}

.grid.homepic3 {
  display: flex;
  align-items: flex-end;
}

.grid.appSearchForm {
  display: flex;
  align-items: center;
}

form.p-fluid .grid.appSearchForm .col-12.xl\:col-4 .field {
  margin-bottom: 0;
}

.col-12.xl\:col-6.summaryValue {
  font-weight: 800;
}

.col-12.xl\:col-8 .card.summary {
  background: #e0e0e070;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  padding: 20px;
}

.card.grid.elements {
  background: transparent;
  box-shadow: none;
}

h2.login {
  margin-top: 4px;
  margin-bottom: -2px;
}

/* Grid End */

.grid.home {
  margin-right: 0;
  margin-left: 0;
}

.grid.footer {
  margin-right: 0;
  position: relative;
  margin-left: 0;
  width: 100%;
}

div#footer\ mobileFooterWrap {
  width: 100%;
}

.col-12.md\:col-12.conditionalForFooter div#homeFooter2 .grid.footer {
  position: fixed;
  bottom: 0;
}

.col-12.md\:col-12.conditionalForFooter div#homeFooter .grid.footer {
  position: absolute;
  bottom: 0px;
}

.col-12.md\:col-12.conditionalForFooter {
  padding-bottom: 0;
  padding-left: 0;
  padding-top: 0;
  padding-right: 0;
  overflow-x: hidden;
}

img.md\:ml-auto.block.md\:h-full {
  padding-top: 7px;
}

.surface-card.p-4.shadow-2.border-round.w-full.lg\:w-6 {
  z-index: 10;
}

.surface-0 {
  background-color: #ffffff75 !important;
  box-shadow: rgba(100, 116, 139, 0.06) 0px 1px 1px, rgba(100, 116, 139, 0.1) 0px 1px 2px;
}

.top-header-line span.p-button-label.p-c {
  color: black;
  padding: 3px;
}

.navButtonsStyle {
  margin-top: 50px;
}

body {
  overflow: overlay;
  font-family: kurdish, "adobe-arabic", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #495057;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background-image: url("../../public/media/Images/line_fingerprint_pattern.png");
  background-size: cover;
}

img.logoLogin {
  width: 27%;
  align-self: center;
}



input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }

  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }

  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}

.customer-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.customer-badge.status-qualified {
  background-color: #51aa34;
  color: #ffffff;
}

.customer-badge.status-unqualified {
  background-color: #ff1400;
  color: #ffffff;
}

.customer-badge.status-negotiation {
  background-color: #f57800;
  color: #ffffff;
}

.customer-badge.status-new {
  background-color: rgb(85, 73, 148);
  color: #ffffff;
}

.customer-badge.status-renewal {
  background-color: #00343b;
  color: #ffffff;
}

.customer-badge.status-proposal {
  background-color: #ffd8b2;
  color: #805b36;
}

.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.product-badge.status-instock {
  background: #c8e6c9;
  color: #256029;
}

.product-badge.status-outofstock {
  background: #ff1400;
  color: #c63737;
}

.product-badge.status-lowstock {
  background: transparent;
  color: #ffffff;
}

.order-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.order-badge.order-delivered {
  background: #c8e6c9;
  color: #256029;
}

.order-badge.order-cancelled {
  background: #ff1400;
  color: #c63737;
}

.order-badge.order-pending {
  background: transparent;
  color: #ffffff;
}

.order-badge.order-returned {
  background: #00343b;
  color: #694382;
}

.image-text {
  vertical-align: middle;
  margin-left: 0.5rem;
}

.p-multiselect-representative-option {
  display: inline-block;
  vertical-align: middle;
}

.p-multiselect-representative-option img {
  vertical-align: middle;
  width: 24px;
}

.p-multiselect-representative-option span {
  margin-top: 0.125rem;
}

.p-column-filter {
  width: 100%;
}

.country-item {
  display: flex;
  align-items: center;
}

.country-item img.flag {
  width: 18px;
  margin-right: 0.5rem;
}

.flag {
  vertical-align: middle;
}

span.flag {
  width: 44px;
  height: 30px;
  display: inline-block;
}

img.flag {
  width: 30px;
}

* {
  box-sizing: border-box;
}

div {
  display: block;
}

/* Main End */

/* Top Navigation */

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(85, 73, 148);
  opacity: 1;
  /* Firefox */
}

.p-component {
  font-family: kurdish, "adobe-arabic", sans-serif;
  font-size: 20px;
  font-weight: normal;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.2s;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.6;
  filter: contrast(0.8);
}

.p-error {
  color: #f44336;
}

.p-warning {
  color: #97653c70;
}

.p-warning-field {
  color: #ffbf00;
}

.p-text-secondary {
  color: #6c757d;
}

.pi {
  font-size: 1rem;
}

.p-link {
  font-size: 20px;
  font-family: kurdish, "adobe-arabic", sans-serif;

  border-radius: 3px;
}

.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  /* box-shadow: 0 0 0 0.2rem #a6d5fa; */
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }

  to {
    background-color: var(--maskbg);
  }
}

@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--maskbg);
  }

  to {
    background-color: transparent;
  }
}

.layout-menubar {
  position: fixed;
}

.p-menu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  width: 12.5rem;
}

.p-menu .p-menuitem-link {
  padding: 0.5rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}

.p-menu .p-menuitem-link .p-menuitem-text {
  color: rgb(85, 73, 148);
}

.p-menu .p-menuitem-link .p-menuitem-icon {
  color: rgb(85, 73, 148);
  margin-right: 0.5rem;
}

.p-menu .p-menuitem-link .p-submenu-icon {
  color: rgb(85, 73, 148);
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: rgb(85, 73, 148)26;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgb(85, 73, 148);
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgb(85, 73, 148);
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgb(85, 73, 148);
}

.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  /* box-shadow: inset 0 0 0 0.15rem rgb(85, 73, 148); */
}

.p-menu.p-menu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.5rem 1rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.p-menubar.p-component {
  box-shadow: rgba(100, 116, 139, 0.06) 0px 1px 1px, rgba(100, 116, 139, 0.1) 0px 1px 2px;
}

.p-menu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}

.card .p-menubar {
  padding: 10px;
}

.p-menubar {
  background-color: #fff;
  color: rgb(85, 73, 148);
  border-radius: 3px;
}

.footer .p-menubar {
  background: transparent;
  background-color: transparent;
  border-bottom: none;
  color: rgb(85, 73, 148);
  border-radius: 3px;
}

.p-menubar .p-menuitem-link {
  padding: 0.5rem 1rem;
  color: rgb(85, 73, 148);
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}

.p-menubar .p-menuitem-link .p-menuitem-text {
  color: rgb(85, 73, 148);
}

.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: rgb(85, 73, 148);
  margin-right: 0.5rem;
}

.p-menubar .p-menuitem-link .p-submenu-icon {
  color: rgb(85, 73, 148);
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: rgb(85, 73, 148)26;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgb(85, 73, 148);
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgb(85, 73, 148);
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgb(85, 73, 148);
}

.p-menubar .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  /* box-shadow: inset 0 0 0 0.15rem rgb(85, 73, 148)26; */
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link {
  padding: 0.5rem 1rem;
  color: rgb(85, 73, 148);
  transition: box-shadow 0.2s;
  user-select: none;
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-text {
  color: rgb(85, 73, 148);
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-icon {
  color: rgb(85, 73, 148);
  margin-right: 0.5rem;
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-submenu-icon {
  color: rgb(85, 73, 148);
  margin-left: 0.5rem;
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover {
  background: rgb(85, 73, 148)26;
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgb(85, 73, 148);
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgb(85, 73, 148);
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgb(85, 73, 148);
}

.p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  /* box-shadow: inset 0 0 0 0.15rem #a6d5fa; */
}

.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link,
.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover {
  background: rgb(85, 73, 148)26;
}

.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgb(85, 73, 148);
}

.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgb(85, 73, 148);
}

.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon,
.p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgb(85, 73, 148);
}

.p-menubar .p-submenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: 12.5rem;
  border-radius: 8px;
}

.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}

.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: 0.875rem;
}

.p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link {
  background: rgb(85, 73, 148)26;
}

.p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text {
  color: rgb(85, 73, 148);
}

.p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon {
  color: rgb(85, 73, 148);
}

.p-menu-overlay {
  position: absolute;
}

.p-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.p-menu .p-menuitem-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}

.p-menu .p-menuitem-text {
  line-height: 1;
}

.p-menubar {
  display: flex;
  align-items: center;
}

.p-menubar ul {
  display: flex;
  padding: 0;
  list-style: none;
}

.p-menubar .p-menuitem-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  position: relative;
}

.p-menubar .p-menuitem-text {
  line-height: 1;
}

.p-menubar .p-menuitem {
  position: relative;
}

.p-menubar-root-list {
  display: flex;
  align-items: center;
  margin: 0;
  margin-left: auto;
  z-index: 11;
}

.p-menubar-root-list>li ul {
  display: none;
  z-index: 1;
}

.p-menubar-root-list>.p-menuitem-active>.p-submenu-list {
  display: block;
}

.p-menubar .p-submenu-list {
  display: none;
  position: absolute;
  z-index: 5;
}

.p-menubar .p-submenu-list>.p-menuitem-active>.p-submenu-list {
  display: block;
  left: 100%;
  top: 0;
}

.p-menubar .p-submenu-list .p-menuitem-link .p-submenu-icon {
  margin-left: auto;
}

.p-menubar .p-menubar-custom,
.p-menubar .p-menubar-end {
  margin-left: 0;
  align-self: center;
}

.p-menubar-button {
  display: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}



.p-inputtext {
  font-family: kurdish, "adobe-arabic", sans-serif;
  font-size: 20px;
  color: rgb(85, 73, 148);
  background: #ffffff;
  padding: 0.5rem 0.75rem;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 8px;
}

.p-inputtext:enabled:hover {
  border-color: #009eac54;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #009eac54
}

.p-inputtext.p-invalid.p-component {
  border-color: #f44336;
}

.p-inputtext.p-warning.p-component {
  border-color: #e79c11;
}

.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.4375rem;
}

.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 0.625rem 0.625rem;
}

/* End Top Navigation */

/* Start Table1 */

.p-datatable {
  position: relative;
}

p-datatable-responsive-scroll>.p-datatable-wrapper>table,
.p-datatable-auto-layout>.p-datatable-wrapper>table {
  table-layout: auto;
}

.p-datatable table {
  border-collapse: collapse;
  min-width: 100%;
}

.p-component,
.p-component * {
  box-sizing: border-box;
}

.p-datatable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
  justify-content: center !important;
}

.p-datatable .p-datatable-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid rgb(85, 73, 148);
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-datatable .p-datatable-footer {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid rgb(85, 73, 148)26;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-datatable .p-datatable-thead>tr>th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid rgb(85, 73, 148);
  border-width: 0 0 2px 0;
  font-weight: 800;
  color: #495057;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
}

.p-datatable .p-datatable-tfoot>tr>td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid rgb(85, 73, 148)26;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #495057;
  background: #e3f2fd;
  margin-left: 0.5rem;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
  background: rgb(85, 73, 148)26;
  color: #495057;
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
  color: #6c757d;
}

.p-datatable .p-sortable-column.p-highlight {
  background: #f8f9fa;
  color: rgb(85, 73, 148);
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: rgb(85, 73, 148);
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
  background: rgb(85, 73, 148)26;
  color: rgb(85, 73, 148);
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
  color: rgb(85, 73, 148);
}

.p-datatable .p-sortable-column:focus {
  /* box-shadow: inset 0 0 0 0.15rem #a6d5fa; */
  outline: 0 none;
}

.p-datatable .p-datatable-tbody>tr {
  background: #ffffff;
  color: #495057;
  transition: box-shadow 0.2s;
  outline-color: #a6d5fa;
}

.p-datatable .p-datatable-tbody>tr>td {
  text-align: left;
  border: 1px solid #e5e5e5;
  border-width: 0 0 2px 0;
  padding: 1rem 1rem;
  outline-color: transparent;
}

.p-datatable .p-datatable-tbody>tr>td .p-row-toggler,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody>tr>td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: rgb(85, 73, 148)26;
}

.p-datatable .p-datatable-tbody>tr>td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save {
  margin-right: 0.5rem;
}

.p-datatable .p-datatable-tbody>tr>td>.p-column-title {
  font-weight: 600;
}

.p-datatable .p-datatable-tbody>tr>td.p-highlight {
  background: #e3f2fd;
  color: #495057;
}

.p-datatable .p-datatable-tbody>tr.p-highlight {
  background: #554994;
  color: #ffffff;
}

.p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-top>td {
  box-shadow: inset 0 2px 0 0 #e3f2fd;
}

.p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-bottom>td {
  box-shadow: inset 0 -2px 0 0 #e3f2fd;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
  background: rgb(85, 73, 148)26;
  color: #495057;
}

.p-datatable .p-column-resizer-helper {
  background: #2196f3;
}

.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #f8f9fa;
}

.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
  border-width: 1px 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td {
  border-width: 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot>tr>td {
  border-width: 1px;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd {
  background: #fcfcfc;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd.p-highlight {
  background: #e3f2fd;
  color: #495057;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd.p-highlight .p-row-toggler {
  color: #495057;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd.p-highlight .p-row-toggler:hover {
  color: #495057;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd+.p-row-expanded {
  background: #fcfcfc;
}

.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-thead>tr>th {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-tfoot>tr>td {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-thead>tr>th {
  padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-tbody>tr>td {
  padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-tfoot>tr>td {
  padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem;
}

.p-datatable-drag-selection-helper {
  background: rgba(33, 150, 243, 0.16);
}

/* End Table1 */

/* Accordition Start */

.p-toggleable-content-enter-done {
  transform: none;
}

.p-accordion-header-text {
  line-height: 1;
}

.p-accordion-header-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
  position: relative;
  text-decoration: none;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.25rem;
  border: 1px solid #dee2e6;
  color: #6c757d;
  background: #f8f9fa;
  font-weight: 700;
  transition: box-shadow 0.2s;
}

.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgb(85, 73, 148)26;
}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: rgb(85, 73, 148)26;
  border-color: #dee2e6;
  color: #343a40;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #f8f9fa;
  border-color: #dee2e6;
  color: #343a40;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: #dee2e6;
  background: #ffffff;
  color: #343a40;
}

.p-accordion .p-accordion-content {
  padding: 1.25rem;
  border: 1px solid #dee2e6;
  color: #495057;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-accordion .p-accordion-tab {
  margin-bottom: 4px;
}

.p-card {
  background: #ffffff;
  color: #495057;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.p-card .p-card-body {
  padding: 1.25rem;
}

.p-card .p-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.p-card .p-card-subtitle {
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: #6c757d;
}

.p-card .p-card-content {
  padding: 1.25rem 0;
}

.p-card .p-card-footer {
  padding: 1.25rem 0 0 0;
}

.p-button.p-button-text {
  background-color: transparent;
  color: rgb(85, 73, 148);
  border-color: transparent;
}

.p-button.p-button-text:enabled:active,
.p-button.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgba(99, 102, 241, 0.16);
  color: #f57800;
  border-color: transparent;
}

.p-button-label {
  font-weight: 700;
}

.p-button.p-button-text:enabled:hover,
.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: rgb(85, 73, 148);
  color: #fff;
  border-color: transparent;
}

.validation-button {
  background: #FFA833 !important
}

.validation-button-content {
  width: 100%;
  justify-content: left;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  z-index: 1;
  display: block;
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: rgb(85, 73, 148);
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

/* Carousel Start */

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: rgb(85, 73, 148);
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: rgb(85, 73, 148);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin: 0.5rem;
}

.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: rgb(78 67 137);
  border-color: transparent;
  background: transparent;
}

.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
}

.p-carousel .p-carousel-indicators {
  padding: 1rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #ced4da;
  width: 2rem;
  height: 0.5rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: transparent;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: rgb(85, 73, 148);
  color: #fff;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: rgb(85, 73, 148);
}

/* Carousel End */

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background-color: rgb(85, 73, 148);
}

/* .p-button:focus {
  /* box-shadow:  0 0 0 4px rgb(85, 73, 148); */
/* .p-button.p-button-secondary:enabled:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #b0b9c6, 0 1px 2px 0 black;
} */
/* .p-button.p-button-success:enabled:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #88eaac, 0 1px 2px 0 black;
}
.p-button.p-button-warning:enabled:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #facf85, 0 1px 2px 0 black;
}
.p-button.p-button-help:enabled:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #d4aafb, 0 1px 2px 0 black;
}
*/

.p-button.p-component {
  margin: 0;
}

.p-button.p-component.p-highlight {
  background-color: #f57800;
}

.p-button.p-component.p-highlight:hover {
  background-color: #e66f00;
}

.accordion-demo .accordion-custom i,
.accordion-demo .accordion-custom span {
  vertical-align: middle;
}

.accordion-demo .accordion-custom span {
  margin: 0 0.5rem;
}

.accordion-demo .p-accordion p {
  line-height: 1.5;
  margin: 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

/* Accordition End */

/* Messages Start */

.p-inline-message {
  padding: 0.5rem 0.75rem;
  margin: 0;
  border-radius: 6px;
}

.p-inline-message.p-inline-message-info {
  background: #e9e9ff;
  border: solid transparent;
  border-width: 0px;
  color: #696cff;
}

.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #696cff;
}

.p-inline-message.p-inline-message-success {
  background: #e4f8f0;
  border: solid #1ea97c;
  border-width: 0px;
  color: #1ea97c;
}

.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #1ea97c;
}

.p-inline-message.p-inline-message-warn {
  background: #fff2e2;
  border: solid #cc8925;
  border-width: 0px;
  color: #cc8925;
}

.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #cc8925;
}

.p-inline-message.p-inline-message-error {
  background: #ffe7e6;
  border: solid #ff5757;
  border-width: 0px;
  color: #ff5757;
}

.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #ff5757;
}

.p-inline-message .p-inline-message-icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}

.p-inline-message .p-inline-message-text {
  font-size: 20px;
}

.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}

.p-message {
  margin: 1rem 0;
  border-radius: 8px;
}

.p-message .p-message-wrapper {
  padding: 1.25rem 1.75rem;
}

.p-message .p-message-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.3);
}

.p-message .p-message-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: transparent;
}

.p-message.p-message-info {
  background: #e9e9ff;
  border: solid #696cff;
  border-width: 0 0 0 6px;
  color: #696cff;
}

.p-message.p-message-info .p-message-icon {
  color: #696cff;
}

.p-message.p-message-info .p-message-close {
  color: #696cff;
}

.p-message.p-message-success {
  background: #e4f8f0;
  border: solid #1ea97c;
  border-width: 0 0 0 6px;
  color: #1ea97c;
}

.p-message.p-message-success .p-message-icon {
  color: #1ea97c;
}

.p-message.p-message-success .p-message-close {
  color: #1ea97c;
}

.p-message.p-message-warn {
  background: #fff2e2;
  border: solid #cc8925;
  border-width: 0 0 0 6px;
  color: #cc8925;
}

.p-message.p-message-warn .p-message-icon {
  color: #cc8925;
}

.p-message.p-message-warn .p-message-close {
  color: #cc8925;
}

.p-message.p-message-error {
  background: #ffe7e6;
  border: solid #ff5757;
  border-width: 0 0 0 6px;
  color: #ff5757;
}

.p-message.p-message-error .p-message-icon {
  color: #ff5757;
}

.p-message.p-message-error .p-message-close {
  color: #ff5757;
}

.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 400;
}

.p-message .p-message-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary>.p-button,
.p-splitbutton.p-button-secondary>.p-button,
.p-fileupload-choose.p-button-secondary {
  color: #ffffff;
  background: #fff;
  border: 1px solid #fff;
}


.p-message-exit {
  opacity: 1;
  max-height: 0;
  margin: 0;
}

.p-message-exit-active {
  opacity: 0;
  max-height: 0;
  margin: 0;
  overflow: hidden;
  position: fixed;
  transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
  transition-duration: 4s;
  transition-timing-function: ease-out;

}

.p-message-exit-active .p-message-close {
  display: none;
  transition: ease-out;
  margin: 0;
}

/* Messages End */


/* Pop Up Message Start */
.p-confirm-popup {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.p-confirm-popup .p-confirm-popup-content {
  padding: 1.5rem;
}

.p-confirm-popup .p-confirm-popup-footer {
  text-align: right;
  padding: 0 1.5rem 1rem 1.5rem;
}

.p-confirm-popup .p-confirm-popup-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}

.p-confirm-popup .p-confirm-popup-footer button:last-child {
  margin: 0;
}

.p-confirm-popup:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}

.p-confirm-popup:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}

.p-confirm-popup.p-confirm-popup-flipped:after {
  border-top-color: #ffffff;
}

.p-confirm-popup.p-confirm-popup-flipped:before {
  border-top-color: #ffffff;
}

.p-confirm-popup .p-confirm-popup-icon {
  font-size: 1.5rem;
}

.p-confirm-popup .p-confirm-popup-message {
  margin-left: 1rem;
}

/* Pop Up Message End */

/* Sidebar start */


.p-sidebar {
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border: 0 none;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.p-sidebar .p-sidebar-header {
  padding: 1rem;
}

.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(0, 0, 0, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
  color: rgba(0, 0, 0, 0.6);
  border-color: transparent;
  background: rgba(0, 0, 0, 0.04);
}

.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-sidebar .p-sidebar-header+.p-sidebar-content {
  padding-top: 0;
}

.p-sidebar .p-sidebar-content {
  padding: 1rem;
}


/* Sidebar End */

/* Input Switch Start */

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}

.p-inputswitch .p-inputswitch-slider {
  background: #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}

.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 4px;
  transition-duration: 0.15s;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.25rem);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #009eac54;
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #ced4da;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: rgb(85, 73, 148);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #ffffff;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: rgb(85, 73, 148);
}

.p-inputswitch.p-invalid {
  border-color: #dc3545;
}

/* Input Switch End */


/* Badge Start */

.p-badge {
  background: #6366f1;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}

.p-badge.p-badge-secondary {
  background-color: #fff;
  color: #ffffff;
}

.p-badge.p-badge-success {
  background-color: #51aa34;
  color: #ffffff;
}

.p-badge.p-badge-info {
  background-color: #2563eb;
  color: #ffffff;
}

.p-badge.p-badge-warning {
  background-color: #2a2d22;
  color: #ffffff;
}

.p-badge.p-badge-danger {
  background-color: #ff1400;
  color: #ffffff;
}

.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}

.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}

/* Badge End */

/* Button Start */

.p-button {
  color: #ffffff;
  background: rgb(85, 73, 148);
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 18px;
  border-radius: 8px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;

}

.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  background: rgb(78 67 137);
  color: #ffffff;
}

.p-button:enabled:active,
.p-button:not(button):not(a):not(.p-disabled):active {
  background: rgb(85, 73, 148);

  color: #ffffff;
}

.p-button.p-button-outlined {
  background-color: transparent;
  color: rgb(85, 73, 148);
  border: 1px solid rgb(85, 73, 148);
}

.p-button.p-button-outlined:enabled:hover,
.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  color: #fff;
  border: 1px solid;
  background: rgb(85, 73, 148);
}

.p-button.p-button-outlined:enabled:active,
.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: transparent;
  color: rgb(85, 73, 148);
  border: 1px solid rgb(85, 73, 148);
}

.p-button.p-button-outlined.p-button-plain {
  color: #6c757d;
  border-color: #6c757d;
}

.p-button.p-button-outlined.p-button-plain:enabled:hover,
.p-button.p-button-outlined.p-button-plain:not(button):not(a):not(.p-disabled):hover {
  background: transparent;
  color: #6c757d;
}

.p-button.p-button-outlined.p-button-plain:enabled:active,
.p-button.p-button-outlined.p-button-plain:not(button):not(a):not(.p-disabled):active {
  background: #dee2e6;
  color: #6c757d;
}

.p-button.p-button-text {
  background-color: transparent;
  color: rgb(85, 73, 148);
  border-color: transparent;
  font-weight: bolder;
}

.p-button.p-button-text:enabled:active,
.p-button.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgb(85, 73, 148);
  color: #fff;
  border-color: transparent;
}

.p-button.p-button-text.p-button-plain {
  color: #6c757d;
}

.p-button.p-button-text.p-button-plain:enabled:hover,
.p-button.p-button-text.p-button-plain:not(button):not(a):not(.p-disabled):hover {
  background: transparent;
  color: #6c757d;
}

.p-button.p-button-text.p-button-plain:enabled:active,
.p-button.p-button-text.p-button-plain:not(button):not(a):not(.p-disabled):active {
  background: #dee2e6;
  color: #6c757d;
}

.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  /* box-shadow: transparent; */
}

.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}

.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}

.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}

.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}

.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: #6366f1;
  background-color: #ffffff;
}

.p-button.p-button-raised {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-button.p-button-rounded {
  border-radius: 2rem;
}

.p-button.p-button-icon-only {
  width: 3rem;
  padding: 0.5rem 0;
}

.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}

.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 3rem;
}

.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.65625rem 1.09375rem;
}

.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}

.p-button.p-button-lg {
  font-size: 1.25rem;
  padding: 0.9375rem 1.5625rem;
}

.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}

.p-button.p-button-loading-label-only.p-button-loading-left .p-button-label {
  margin-left: 0.5rem;
}

.p-button.p-button-loading-label-only.p-button-loading-right .p-button-label {
  margin-right: 0.5rem;
}

.p-button.p-button-loading-label-only.p-button-loading-top .p-button-label {
  margin-top: 0.5rem;
}

.p-button.p-button-loading-label-only.p-button-loading-bottom .p-button-label {
  margin-bottom: 0.5rem;
}

.p-button.p-button-loading-label-only .p-button-loading-icon {
  margin: 0;
}

/* .p-fluid .p-button {
  width: 100%;
} */
/* .p-fluid .p-button-icon-only {
  width: 3rem;
} */
.p-fluid .p-buttonset {
  display: flex;
}

.p-fluid .p-buttonset .p-button {
  flex: 1;
}

/* form.p-fluid {
  background: #e0e0e070;
  padding: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
} */

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary>.p-button,
.p-splitbutton.p-button-secondary>.p-button,
.p-fileupload-choose.p-button-secondary {
  color: #ffffff;
  background: rgb(85, 73, 148);
  border: 1px solid transparent;
}

.p-button.p-button-secondary:enabled:hover,
.p-button.p-button-secondary:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary>.p-button:enabled:hover,
.p-buttonset.p-button-secondary>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary>.p-button:enabled:hover,
.p-splitbutton.p-button-secondary>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary:enabled:hover,
.p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):hover {
  background: rgb(78 67 137);
  color: #ffffff;
  border-color: transparent;
}

.p-button.p-button-secondary:enabled:focus,
.p-button.p-button-secondary:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-secondary>.p-button:enabled:focus,
.p-buttonset.p-button-secondary>.p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-secondary>.p-button:enabled:focus,
.p-splitbutton.p-button-secondary>.p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-secondary:enabled:focus,
.p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):focus {
  box-shadow: transparent;
}

.p-button.p-button-secondary:enabled:active,
.p-button.p-button-secondary:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-secondary>.p-button:enabled:active,
.p-buttonset.p-button-secondary>.p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary>.p-button:enabled:active,
.p-splitbutton.p-button-secondary>.p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary:enabled:active,
.p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):active {
  background: rgb(85, 73, 148);
  color: #ffffff;
  border-color: rgb(85, 73, 148);
}

.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary>.p-button.p-button-outlined,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-secondary.p-button-outlined {
  background-color: transparent;
  color: rgb(85, 73, 148);
  border: 1px solid rgb(85, 73, 148);
}

.p-button.p-button-secondary.p-button-outlined:enabled:hover,
.p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary>.p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-secondary>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: transparent;
  color: #fff;
  border: 1px solid transparent;
}

.p-button.p-button-secondary.p-button-outlined:enabled:active,
.p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-secondary>.p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-secondary>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: rgba(100, 116, 139, 0.16);
  color: #fff;
  border: 1px solid;
}

.p-button.p-button-secondary.p-button-text,
.p-buttonset.p-button-secondary>.p-button.p-button-text,
.p-splitbutton.p-button-secondary>.p-button.p-button-text,
.p-fileupload-choose.p-button-secondary.p-button-text {
  background-color: transparent;
  color: rgb(85, 73, 148);
  border-color: transparent;
}

.p-button.p-button-secondary.p-button-text:enabled:hover,
.p-button.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary>.p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-secondary>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary>.p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-secondary>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: rgb(85, 73, 148);
  border-color: transparent;
  color: #fff;
}

.p-button.p-button-secondary.p-button-text:enabled:active,
.p-button.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-secondary>.p-button.p-button-text:enabled:active,
.p-buttonset.p-button-secondary>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-secondary>.p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-secondary>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-secondary.p-button-text:enabled:active,
.p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgba(100, 116, 139, 0.16);
  border-color: transparent;
  color: #fff;
}

.p-button.p-button-info,
.p-buttonset.p-button-info>.p-button,
.p-splitbutton.p-button-info>.p-button,
.p-fileupload-choose.p-button-info {
  color: #fff;
  background: #a855f7;
  border: 1px solid #a855f7;
}

.p-button.p-button-info:enabled:hover,
.p-button.p-button-info:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-info>.p-button:enabled:hover,
.p-buttonset.p-button-info>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-info>.p-button:enabled:hover,
.p-splitbutton.p-button-info>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info:enabled:hover,
.p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):hover {
  background: #8b45cf;
  color: #ffffff;
  border-color: transparent;
}

.p-button.p-button-info:enabled:active,
.p-button.p-button-info:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-info>.p-button:enabled:active,
.p-buttonset.p-button-info>.p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-info>.p-button:enabled:active,
.p-splitbutton.p-button-info>.p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-info:enabled:active,
.p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):active {
  background: #8b45cf;
  color: #ffffff;
}

.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info>.p-button.p-button-outlined,
.p-splitbutton.p-button-info>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-info.p-button-outlined {
  background-color: transparent;
  color: #a855f7;
  border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:hover,
.p-button.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-info>.p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-info>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: #a855f7;
  color: #fff;
  border: 1px solid transparent;
}

.p-button.p-button-info.p-button-outlined:enabled:active,
.p-button.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-info>.p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-info>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-info.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: transparent;
  color: #a855f7;
  border: 1px solid;
}

.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info>.p-button.p-button-outlined,
.p-splitbutton.p-button-info>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-info.p-button-outlined {
  background-color: transparent;
  color: #a855f7;
  border: 1px solid;
}

.p-button.p-button-info.p-button-text,
.p-buttonset.p-button-info>.p-button.p-button-text,
.p-splitbutton.p-button-info>.p-button.p-button-text,
.p-fileupload-choose.p-button-info.p-button-text {
  background-color: transparent;
  color: #a855f7;
  border-color: transparent;
}

.p-button.p-button-info.p-button-text:enabled:hover,
.p-button.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-info>.p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-info>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-info>.p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-info>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-info.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: #a855f7;
  border-color: transparent;
  color: #fff;
}

.p-button.p-button-info.p-button-text:enabled:active,
.p-button.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-info>.p-button.p-button-text:enabled:active,
.p-buttonset.p-button-info>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-info>.p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-info>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-info.p-button-text:enabled:active,
.p-fileupload-choose.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgba(59, 130, 246, 0.16);
  border-color: transparent;
  color: #f57800;
}

.p-button.p-button-success,
.p-buttonset.p-button-success>.p-button,
.p-splitbutton.p-button-success>.p-button,
.p-fileupload-choose.p-button-success {
  color: #ffffff;
  background: #51aa34;
  border: 1px solid #51aa34;
}

.p-button.p-button-success:enabled:hover,
.p-button.p-button-success:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success>.p-button:enabled:hover,
.p-buttonset.p-button-success>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success>.p-button:enabled:hover,
.p-splitbutton.p-button-success>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success:enabled:hover,
.p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):hover {
  background: #47922f;
  color: #fff;
  border-color: transparent;
}

.p-button.p-button-success:enabled:focus,
.p-button.p-button-success:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-success>.p-button:enabled:focus,
.p-buttonset.p-button-success>.p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-success>.p-button:enabled:focus,
.p-splitbutton.p-button-success>.p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-success:enabled:focus,
.p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):focus {
  box-shadow: transparent;
}

.p-button.p-button-success:enabled:active,
.p-button.p-button-success:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-success>.p-button:enabled:active,
.p-buttonset.p-button-success>.p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success>.p-button:enabled:active,
.p-splitbutton.p-button-success>.p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success:enabled:active,
.p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):active {
  background: #15803d;
  color: #ffffff;
  border-color: #15803d;
}

.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success>.p-button.p-button-outlined,
.p-splitbutton.p-button-success>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-success.p-button-outlined {
  background-color: transparent;
  color: #51aa34;
  border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:enabled:hover,
.p-button.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success>.p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-success>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success>.p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-success>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: #51aa34;
  color: #fff;
  border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:enabled:active,
.p-button.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-success>.p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-success>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success>.p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-success>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: transparent;
  color: #51aa34;
  border: 1px solid;
}

.p-button.p-button-success.p-button-text,
.p-buttonset.p-button-success>.p-button.p-button-text,
.p-splitbutton.p-button-success>.p-button.p-button-text,
.p-fileupload-choose.p-button-success.p-button-text {
  background-color: transparent;
  color: #51aa34;
  border-color: transparent;
}

.p-button.p-button-success.p-button-text:enabled:hover,
.p-button.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-success>.p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-success>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-success>.p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-success>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-success.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: #51aa34;
  border-color: transparent;
  color: #fff;
}

.p-button.p-button-success.p-button-text:enabled:active,
.p-button.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-success>.p-button.p-button-text:enabled:active,
.p-buttonset.p-button-success>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-success>.p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-success>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-success.p-button-text:enabled:active,
.p-fileupload-choose.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: transparent;
  border-color: transparent;
  color: #51aa34;
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning>.p-button,
.p-splitbutton.p-button-warning>.p-button,
.p-fileupload-choose.p-button-a {
  color: #ffffff;
  background: #2a2d22;
  border: 1px solid transparent;
}

.p-button.p-button-warning:enabled:hover,
.p-button.p-button-warning:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-warning>.p-button:enabled:hover,
.p-buttonset.p-button-warning>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-warning>.p-button:enabled:hover,
.p-splitbutton.p-button-warning>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning:enabled:hover,
.p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):hover {
  background: #434639;
  color: #ffffff;
  border-color: 1px solid transparent;
}

.p-button.p-button-warning:enabled:focus,
.p-button.p-button-warning:not(button):not(a):not(.p-disabled):focus,
.p-buttonset.p-button-warning>.p-button:enabled:focus,
.p-buttonset.p-button-warning>.p-button:not(button):not(a):not(.p-disabled):focus,
.p-splitbutton.p-button-warning>.p-button:enabled:focus,
.p-splitbutton.p-button-warning>.p-button:not(button):not(a):not(.p-disabled):focus,
.p-fileupload-choose.p-button-warning:enabled:focus,
.p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):focus {
  box-shadow: none;
}

.p-button.p-button-warning:enabled:active,
.p-button.p-button-warning:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-warning>.p-button:enabled:active,
.p-buttonset.p-button-warning>.p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-warning>.p-button:enabled:active,
.p-splitbutton.p-button-warning>.p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning:enabled:active,
.p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):active {
  background: #2a2d22;
  color: #ffffff;
  border-color: transparent;
}

.p-button.p-button-warning.p-button-outlined,
.p-buttonset.p-button-warning>.p-button.p-button-outlined,
.p-splitbutton.p-button-warning>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-warning.p-button-outlined {
  background-color: transparent;
  color: #2a2d22;
  border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:enabled:hover,
.p-button.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-warning>.p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-warning>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-warning>.p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-warning>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: #2a2d22;
  color: #fff;
  border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:enabled:active,
.p-button.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-warning>.p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-warning>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-warning>.p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-warning>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: transparent;
  color: #2a2d22;
  border: 1px solid;
}

.p-button.p-button-warning.p-button-text,
.p-buttonset.p-button-warning>.p-button.p-button-text,
.p-splitbutton.p-button-warning>.p-button.p-button-text,
.p-fileupload-choose.p-button-warning.p-button-text {
  background-color: transparent;
  color: #2a2d22;
  border-color: transparent;
}

.p-button.p-button-warning.p-button-text:enabled:hover,
.p-button.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-warning>.p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-warning>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-warning>.p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-warning>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-warning.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: #2a2d22;
  border-color: transparent;
  color: #fff;
}

.p-button.p-button-warning.p-button-text:enabled:active,
.p-button.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-warning>.p-button.p-button-text:enabled:active,
.p-buttonset.p-button-warning>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-warning>.p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-warning>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-warning.p-button-text:enabled:active,
.p-fileupload-choose.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: rgba(245, 158, 11, 0.16);
  border-color: transparent;
  color: #2a2d22;
}

.p-button.p-button-help,
.p-buttonset.p-button-help>.p-button,
.p-splitbutton.p-button-help>.p-button,
.p-fileupload-choose.p-button-help {
  color: #ffffff;
  background: #a855f7;
  border: 1px solid #a855f7;
}

.p-button.p-button-help:enabled:hover,
.p-button.p-button-help:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-help>.p-button:enabled:hover,
.p-buttonset.p-button-help>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-help>.p-button:enabled:hover,
.p-splitbutton.p-button-help>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help:enabled:hover,
.p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):hover {
  background: #7039a5;
  color: #ffffff;
  border-color: transparent;
}

.p-button.p-button-help:enabled:active,
.p-button.p-button-help:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-help>.p-button:enabled:active,
.p-buttonset.p-button-help>.p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-help>.p-button:enabled:active,
.p-splitbutton.p-button-help>.p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-help:enabled:active,
.p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):active {
  background: #7e22ce;
  color: #ffffff;
  border-color: #7e22ce;
}

.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help>.p-button.p-button-outlined,
.p-splitbutton.p-button-help>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-help.p-button-outlined {
  background-color: transparent;
  color: #f57800;
  border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:enabled:hover,
.p-button.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-help>.p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-help>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-help>.p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-help>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: #f57800;
  color: #fff;
}

.p-button.p-button-help.p-button-outlined:enabled:active,
.p-button.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-help>.p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-help>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-help>.p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-help>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-help.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: transparent;
  color: #f57800;
  border: 1px solid;
}

.p-button.p-button-help.p-button-text,
.p-buttonset.p-button-help>.p-button.p-button-text,
.p-splitbutton.p-button-help>.p-button.p-button-text,
.p-fileupload-choose.p-button-help.p-button-text {
  background-color: transparent;
  color: #f57800;
  border-color: transparent;
}

.p-button.p-button-help.p-button-text:enabled:hover,
.p-button.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-help>.p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-help>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-help>.p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-help>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-help.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: #f57800;
  border-color: transparent;
  color: #fff;
}

.p-button.p-button-help.p-button-text:enabled:active,
.p-button.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-help>.p-button.p-button-text:enabled:active,
.p-buttonset.p-button-help>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-help>.p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-help>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-help.p-button-text:enabled:active,
.p-fileupload-choose.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: transparent;
  border-color: transparent;
  color: #a855f7;
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger>.p-button,
.p-splitbutton.p-button-danger>.p-button,
.p-fileupload-choose.p-button-danger {
  color: #ffffff;
  background: #ff1400;
}

.p-button.p-button-danger:enabled:hover,
.p-button.p-button-danger:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger>.p-button:enabled:hover,
.p-buttonset.p-button-danger>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger>.p-button:enabled:hover,
.p-splitbutton.p-button-danger>.p-button:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger:enabled:hover,
.p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):hover {
  background: #cc1000;
  color: #ffffff;
  border-color: transparent;
}

.p-button.p-button-danger:enabled:active,
.p-button.p-button-danger:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger>.p-button:enabled:active,
.p-buttonset.p-button-danger>.p-button:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger>.p-button:enabled:active,
.p-splitbutton.p-button-danger>.p-button:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger:enabled:active,
.p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):active {
  background: #b91c1c;
  color: #ffffff;
  border-color: #b91c1c;
}

.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger>.p-button.p-button-outlined,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined,
.p-fileupload-choose.p-button-danger.p-button-outlined {
  background-color: transparent;
  color: #ff1400;
  border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:enabled:hover,
.p-button.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger>.p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-danger>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: transparent;
  color: #fff;
  border: 1px solid transparent;
}

.p-button.p-button-danger.p-button-outlined:enabled:active,
.p-button.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger>.p-button.p-button-outlined:enabled:active,
.p-buttonset.p-button-danger>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-danger>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger.p-button-outlined:enabled:active,
.p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):active {
  background: transparent;
  color: #ff1400;
  border: 1px solid;
}

.p-button.p-button-danger.p-button-text,
.p-buttonset.p-button-danger>.p-button.p-button-text,
.p-splitbutton.p-button-danger>.p-button.p-button-text,
.p-fileupload-choose.p-button-danger.p-button-text {
  background-color: transparent;
  color: #ff1400;
  border-color: transparent;
}

.p-button.p-button-danger.p-button-text:enabled:hover,
.p-button.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-danger>.p-button.p-button-text:enabled:hover,
.p-buttonset.p-button-danger>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-danger>.p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-danger>.p-button.p-button-text:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-danger.p-button-text:enabled:hover,
.p-fileupload-choose.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):hover {
  background: #ff1400;
  border-color: transparent;
  color: #fff;
}

.p-button.p-button-danger.p-button-text:enabled:active,
.p-button.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-buttonset.p-button-danger>.p-button.p-button-text:enabled:active,
.p-buttonset.p-button-danger>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-splitbutton.p-button-danger>.p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-danger>.p-button.p-button-text:not(button):not(a):not(.p-disabled):active,
.p-fileupload-choose.p-button-danger.p-button-text:enabled:active,
.p-fileupload-choose.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):active {
  background: transparent;
  border-color: transparent;
  color: #ff1400;
}

.p-button.p-button-link {
  color: rgb(85, 73, 148);
  background: transparent;
  border: transparent;
}

.p-button.p-button-link:enabled:hover,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):hover {
  background: transparent;
  color: rgb(85, 73, 148);
  border-color: transparent;
}

.p-button.p-button-link:enabled:hover .p-button-label,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):hover .p-button-label {
  text-decoration: underline;
}

.p-button.p-button-link:enabled:focus,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):focus {
  background: transparent;
  box-shadow: transparent;
  border-color: transparent;
}

.p-button.p-button-link:enabled:active,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):active {
  background: transparent;
  color: rgb(85, 73, 148);
  border-color: transparent;
}

.p-speeddial-button.p-button.p-button-icon-only {
  width: 4rem;
  height: 4rem;
}

.p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
  font-size: 1.3rem;
}

/* Button End */

/* Listbox Start */

.p-listbox {
  background: #ffffff;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 6px;
}

.p-listbox .p-listbox-header {
  padding: 0.5rem 1.25rem;
  /* border-bottom: 1px solid #f57800; */
  color: #343a40;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-listbox .p-listbox-header .p-listbox-filter {
  padding-right: 1.75rem;
}

.p-listbox .p-listbox-header .p-listbox-filter-icon {
  right: 0.75rem;
  color: var(--green);
}

.p-listbox .p-listbox-list {
  padding: 0.5rem 0;
}

.p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 0.5rem 1.25rem;
  border: 0 none;
  color: #495057;
  transition: box-shadow 0.2s;
  border-radius: 0;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #ffff;
  background: #554994;
  font-weight: 800;
  border-radius: 8px;
}

.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
}

.p-listbox .p-listbox-list .p-listbox-item-group {
  margin: 0;
  padding: 0.5rem 1.25rem;
  color: #343a40;
  background: #ffffff;
  font-weight: 700;
}

.p-listbox .p-listbox-list .p-listbox-empty-message {
  padding: 0.5rem 1.25rem;
  color: #495057;
  background: transparent;
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: rgb(85, 73, 148)26;
}

.p-listbox.p-invalid {
  border-color: #e24c4c;
}

/* Listbox End */

/* Dialog Start */

/* DialogDemo.css */

.dialog-demo .p-button {
  margin: 0 0.5rem 0 0;
  min-width: 10rem;
}

.dialog-demo p {
  margin: 0;
  line-height: 1.5;
}

.dialog-demo .p-dialog .p-button {
  min-width: 6rem;
}

.p-dialog {
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: 0 none;
}

.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #ffffff;
  color: #343a40;
  padding: 1.5rem;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 700;
  font-size: 1.25rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #343a40;
  border-color: transparent;
  background: rgb(85, 73, 148)26;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: transparent;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}

.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #495057;
  padding: 0 1.5rem 2rem 1.5rem;
}

.p-dialog .p-dialog-footer {
  border-top: 0 none;
  background: #ffffff;
  color: #495057;
  padding: 0 1.5rem 1.5rem 1.5rem;
  text-align: right;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-overlaypanel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 1.25rem;
}

.p-overlaypanel .p-overlaypanel-close {
  background: #6366f1;
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}

.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: #4f46e5;
  color: #ffffff;
}

.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}

.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}

.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #ffffff;
}

.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: #ffffff;
}

/* Dialog End */

/* PanelMenu Start */

.p-panelmenu .p-panelmenu-header>a {
  padding: 1.25rem;
  border: 1px solid #dee2e6;
  color: rgb(85, 73, 148);
  background: #f8f9fa;
  font-weight: 700;
  transition: box-shadow 0.2s;
}

.p-panelmenu .p-panelmenu-header>a .p-panelmenu-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header>a .p-menuitem-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header>a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgb(85, 73, 148)26;
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled)>a:hover {
  background: rgb(85, 73, 148)26;
  border-color: #dee2e6;
  color: #343a40;
}


.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
  border: 1px solid rgb(85, 73, 148);
}

.p-panelmenu .p-panelmenu-header.p-highlight>a {
  background: transparent;
  border-color: rgb(85, 73, 148)26;
  color: rgb(85, 73, 148);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled)>a:hover {
  border-color: rgb(85, 73, 148)26;
  background: rgb(85, 73, 148)26;
  color: rgb(85, 73, 148);
}

.p-panelmenu .p-panelmenu-content {
  padding: 0.25rem 0;
  border: 1px solid rgb(85, 73, 148)26;
  background: #ffffff;
  color: #495057;
  margin-bottom: 4px;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.5rem 1.25rem;
  color: rgb(85, 73, 148);
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: rgb(85, 73, 148);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: rgb(85, 73, 148);
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: rgb(85, 73, 148);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: rgb(85, 73, 148)26;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #6c757d;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #6c757d;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 1px rgb(85, 73, 148)26;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}

.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 4px;
}

/* PanelMenu End */

/* DataTable Start */

.datatable-doc-demo .p-paginator .p-paginator-current {
  margin-left: auto;
}

.datatable-doc-demo .p-progressbar {
  height: 0.5rem;
  background-color: #d8dadc;
}

.datatable-doc-demo .p-progressbar .p-progressbar-value {
  background-color: rgb(85, 73, 148);
}

.datatable-doc-demo .p-datepicker {
  min-width: 25rem;
}

.datatable-doc-demo .p-datepicker td {
  font-weight: 400;
}

.datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-header {
  padding: 1rem;
  text-align: left;
  font-size: 1.5rem;
}

.datatable-doc-demo .p-datatable.p-datatable-customers .p-paginator {
  padding: 1rem;
}

.datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-thead>tr>th {
  text-align: left;
}

.datatable-doc-demo .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
  text-transform: uppercase;
}

.p-input-icon-left>i:first-of-type,
.p-input-icon-left>svg:first-of-type,
.p-input-icon-left>.p-input-prefix {
  left: 0.75rem;
  color: rgb(85, 73, 148);
}

.p-paginator {
  background: #ffffff;
  color: #6c757d;
  border: solid #e9ecef;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 6px;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: transparent;
  color: #343a40;
}

.p-paginator .p-paginator-first {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

/* .p-paginator .p-paginator-last {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
} */
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  height: 3rem;
  display: flex;
  align-items: center;
}

.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}

.p-paginator .p-paginator-page-input {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.p-paginator .p-paginator-page-input .p-inputtext {
  max-width: 3rem;
}

.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  padding: 0 0.5rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #eef2ff;
  border-color: #eef2ff;
  color: rgb(85, 73, 148);
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: #e9ecef;
  border-color: transparent;
  color: #343a40;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}

.p-column-filter-menu-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-column-filter-menu-button:hover {
  color: #343a40;
  border-color: transparent;
  background: #e9ecef;
}

.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: #e9ecef;
  color: #343a40;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: #eef2ff;
  color: rgb(85, 73, 148);
}

.p-column-filter-menu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: transparent;
}

.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-column-filter-clear-button:hover {
  color: #343a40;
  border-color: transparent;
  background: #e9ecef;
}

.p-column-filter-clear-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: transparent;
}

.p-column-filter-overlay {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  min-width: 12.5rem;
}

.p-column-filter-overlay .p-column-filter-row-items {
  padding: 0.5rem 0;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  margin: 0;
  padding: 0.5rem 1.25rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
  color: rgb(85, 73, 148);
  background: #eef2ff;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: transparent;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 1px #c7d2fe;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
  padding: 0.5rem 1.25rem;
  border-bottom: 1px solid #dee2e6;
  color: #343a40;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 1.25rem;
  border-bottom: 1px solid #dee2e6;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
  margin-top: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: 0 none;
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 0.5rem 1.25rem;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 1.25rem;
}

.p-input-icon-left>i:first-of-type,
.p-input-icon-left>svg:first-of-type,
.p-input-icon-left>.p-input-prefix {
  left: 0.75rem;
  color: #6c757d;
}

.p-input-icon-left>.p-inputtext {
  padding-left: 2.5rem;
}

.p-input-icon-left.p-float-label>label {
  left: 2.5rem;
}

.p-input-icon-right>i:last-of-type,
.p-input-icon-right>svg:last-of-type,
.p-input-icon-right>.p-input-suffix {
  right: 0.75rem;
  color: #6c757d;
}

.p-input-icon-right>.p-inputtext {
  padding-right: 2.5rem;
}

.p-dropdown {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 8px;
}

.p-dropdown:not(.p-disabled):hover {
  border: 1px solid rgb(85, 73, 148)26;
}

.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgb(85, 73, 148)26;
  border-color: rgb(85, 73, 148)26;
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.75rem;
}

.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
}

.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}

.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #6c757d;
  width: 3rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-dropdown .p-dropdown-clear-icon {
  color: #6c757d;
  right: 3rem;
}

.p-dropdown.p-invalid.p-component {
  border-color: #e24c4c;
}

.p-dropdown.p-warning.p-component {
  border-color: #e79c11;
}

.p-dropdown-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.p-dropdown-panel .p-dropdown-header {
  padding: 0.5rem 1.25rem;
  border-bottom: 1px solid #dee2e6;
  color: #343a40;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.75rem;
  margin-right: -1.75rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 0.75rem;
  color: #6c757d;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter {
  padding-right: 3.5rem;
  margin-right: -3.5rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter-clear-icon {
  right: 2.5rem;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0.5rem 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.5rem 1.25rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: rgb(85, 73, 148);
  background: #eef2ff;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: rgb(85, 73, 148)26;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.5rem 1.25rem;
  color: #343a40;
  background: #ffffff;
  font-weight: 700;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.5rem 1.25rem;
  color: #495057;
  background: transparent;
}

.p-checkbox {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}

.p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 22px;
  height: 22px;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 8px;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: transparent;
  background: rgb(85, 73, 148);
}

.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: #f57800;
  background: transparent;
  color: #ffffff;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #495057;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: #ced4da;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: rgb(85, 73, 148);
  background: rgb(85, 73, 148);
  color: #ffffff;
}

.p-checkbox.p-invalid>.p-checkbox-box {
  border-color: #e24c4c;
}

.p-checkbox.p-warning>.p-checkbox-box {
  border-color: #e79c11;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #f57800;
}

.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: transparent;
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: transparent;
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: rgb(85, 73, 148);
}

/* DataTable End */

/* TreeTable Start */

.p-tree {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 1.25rem;
  border-radius: 6px;
}

.p-tree .p-tree-container .p-treenode {
  padding: 0.143rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 6px;
  transition: box-shadow 0.2s;
  padding: 0.5rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #495057;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: #343a40;
  border-color: transparent;
  background: #e9ecef;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: #495057;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content checkbox {
  margin-right: 0.5rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #495057;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: #554994;
  color: #fff;
  font-weight: 800;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:not(.p-highlight):not(.p-disabled):hover {
  background: rgb(85, 73, 148)26;
  color: #495057;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #fff;
  font-weight: 800;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
  color: #fff;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: rgb(85, 73, 148)26;
  color: #495057;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background: #e9ecef;
  color: #495057;
}

.p-tree .p-tree-filter-container {
  margin-bottom: 0.5rem;
}

.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 1.75rem;
}

.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 0.75rem;
  color: rgb(85, 73, 148);
}

.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}

.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}

.p-tree .p-treenode-droppoint {
  height: 0.5rem;
}

.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background: #8ba7ff;
}

.p-treetable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-treetable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}

.p-treetable .p-treetable-header {
  background: #f8f9fa;
  color: #343a40;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 700;
}

.p-treetable .p-treetable-footer {
  background: #f8f9fa;
  color: #343a40;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 700;
}

.p-treetable .p-treetable-thead>tr>th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  font-weight: 700;
  color: #343a40;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
}

.p-treetable .p-treetable-tfoot>tr>td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  font-weight: 700;
  color: #343a40;
  background: #f8f9fa;
}

.p-treetable .p-sortable-column {
  outline-color: #c7d2fe;
}

.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #f57800;
  margin-left: 0.5rem;
}

.p-treetable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: rgb(85, 73, 148);
  background: #eef2ff;
  margin-left: 0.5rem;
}

.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: #e9ecef;
  color: #f57800;
}

.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #f57800;
}

.p-treetable .p-sortable-column.p-highlight {
  background: #eef2ff;
  color: rgb(85, 73, 148);
}

.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: rgb(85, 73, 148);
}

.p-treetable .p-treetable-tbody>tr {
  background: #ffffff;
  color: #495057;
  transition: box-shadow 0.2s;
  outline-color: #c7d2fe;
}

.p-treetable .p-treetable-tbody>tr>td {
  text-align: left;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler {
  width: 2rem;
  height: 2rem;
  color: rgb(85, 73, 148);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler:enabled:hover {
  color: rgb(85, 73, 148);
  border-color: transparent;
  background: #e9ecef;
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: transparent;
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler+.p-checkbox {
  margin-right: 0.5rem;
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler+.p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #495057;
}

.p-treetable .p-treetable-tbody>tr.p-highlight {
  background: #eef2ff;
  color: rgb(85, 73, 148);
}

.p-treetable .p-treetable-tbody>tr.p-highlight .p-treetable-toggler {
  color: rgb(85, 73, 148);
}

.p-treetable .p-treetable-tbody>tr.p-highlight .p-treetable-toggler:hover {
  color: rgb(85, 73, 148);
}

.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody>tr:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}

.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody>tr:not(.p-highlight):hover .p-treetable-toggler {
  color: #495057;
}

.p-treetable .p-column-resizer-helper {
  background: #6366f1;
}

.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: #f8f9fa;
}

.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}

.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-thead>tr>th {
  border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tbody>tr>td {
  border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tfoot>tr>td {
  border-width: 1px;
}

.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 0.875rem 0.875rem;
}

.p-treetable.p-treetable-sm .p-treetable-thead>tr>th {
  padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-tbody>tr>td {
  padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-tfoot>tr>td {
  padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-thead>tr>th {
  padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-tbody>tr>td {
  padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-tfoot>tr>td {
  padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 1.25rem 1.25rem;
}

/* TreeTable End */

/* TabView Start */

.tabview-demo .tabview-header-icon i,
.tabview-demo .tabview-header-icon span {
  vertical-align: middle;
}

.tabview-demo .tabview-header-icon span {
  margin: 0 0.5rem;
}

.tabview-demo .p-tabview p {
  line-height: 1.5;
  margin: 0;
}

.p-tabview .p-tabview-nav {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-width: 0 0 2px 0;
}

.p-tabview .p-tabview-nav li {
  margin-right: 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid #dee2e6;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #dee2e6 transparent;
  background: #ffffff;
  color: #6c757d;
  padding: 1.25rem;
  font-weight: 700;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  /* box-shadow: inset transparent; */
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: #ffffff;
  border-color: #adb5bd;
  color: #6c757d;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: rgb(85, 73, 148);
  color: rgb(85, 73, 148);
}

.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}

.p-tabview .p-tabview-nav-btn.p-link {
  background: #ffffff;
  color: #6366f1;
  width: 3rem;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
}

.p-tabview .p-tabview-nav-btn.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset transparent;
}

.p-tabview .p-tabview-panels {
  background: #ffffff;
  padding: 1.25rem;
  border: 0 none;
  color: #495057;
}

/* TabView End */

/* Tooltip Start */

.p-tooltip .p-tooltip-text {
  background: #495057;
  color: #ffffff;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #495057;
}

.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #495057;
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #495057;
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #495057;
}

.p-slider {
  background: #dee2e6;
  border: 0 none;
  border-radius: 6px;
}

.p-slider.p-slider-horizontal {
  height: 0.286rem;
}

.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.5715rem;
  margin-left: -0.5715rem;
}

.p-slider.p-slider-vertical {
  width: 0.286rem;
}

.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.5715rem;
  margin-bottom: -0.5715rem;
}

.p-slider .p-slider-handle {
  height: 1.143rem;
  width: 1.143rem;
  background: #ffffff;
  border: 2px solid rgb(85, 73, 148);
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
}

.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgb(85, 73, 148);
}

.p-slider .p-slider-range {
  background: rgb(85, 73, 148);
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: rgb(85, 73, 148);
  border-color: rgb(85, 73, 148);
}

.p-knob-range {
  stroke: LightGray;
}

.p-knob-value {
  stroke: rgb(85, 73, 148);
}

/* .p-button.p-button-secondary:enabled:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #b0b9c6, 0 1px 2px 0 black;
} */

.p-button.p-button-secondary.p-button-outlined:enabled:hover,
.p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-buttonset.p-button-secondary>.p-button.p-button-outlined:enabled:hover,
.p-buttonset.p-button-secondary>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-secondary>.p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover,
.p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:hover,
.p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
  background: rgb(85, 73, 148);
  color: #f8f9fa;
  border: 1px solid transparent;
}

/* Tooltip End */

/* FileUpload Start */

i.pi.pi-image.mt-3.p-5 {
  font-size: 7em;
  border-radius: 50%;
  background-color: var(--surface-b);
  color: var(--surface-d);
}

.p-progressbar {
  border: 0 none;
  height: 0.5rem;
  background: #dee2e6;
}

.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #6366f1;
}

.p-progressbar .p-progressbar-label {
  color: #495057;
  line-height: 1.5rem;
}

.p-progressbar-value.p-progressbar-value-animate {
  font-size: 13px;
}

/* FileUpload End */

/* ScrollToTop Start */

.custom-scrolltop {
  width: 2rem;
  height: 2rem;
  background-color: var(--primary-color);
  margin-right: 10px;
}

.custom-scrolltop:hover {
  background-color: var(--primary-color);
}

.custom-scrolltop .p-scrolltop-icon {
  font-size: 1rem;
  color: var(--primary-color-text);
}

.custom-scrolltop:hover {
  background-color: #008592;
}

/* ScrollToTop End */

input.p-inputtext.p-component {
  color: black;
}

/* Steps Start */

.c-stepper-select {
  text-decoration: none;
  color: #495057;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  text-align: center;
  --size: 3rem;
}

.c-stepper__title {
  font-weight: bold;
  font-size: clamp(1rem, 4vw, 1.25rem);
  margin-bottom: 0.5rem;
  margin: 0;
}

.c-stepper__desc {
  color: grey;
  font-size: clamp(0.85rem, 2vw, 1rem);
  padding-left: var(--spacing);
  padding-right: var(--spacing);
  margin: 2px;
}

:root {
  --circle-size: clamp(1.5rem, 5vw, 3rem);
  --spacing: clamp(0.25rem, 2vw, 0.5rem);
}

.c-stepper {
  display: flex;
}

/* Step 1 */

.linkStep {
  text-decoration: none;
}

.c-stepper__item_1 {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.c-stepper__item_1::before {
  content: "";
  display: flex;
  height: 62px;
  width: 51px;
  background-size: cover;
}

.c-stepper__item_1:active::before,
.c-stepper__item_1:focus::before,
.c-stepper__item_1:hover::before {
  height: 62px;
  width: 51px;
  background-size: cover;
}


.card.navigation {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  padding: 1%;
  margin: 1%;
  background-color: #fff;
  border-radius: 8px;
}

.presentation.col.active {
  cursor: pointer;
}

.presentation.col.active span.round-tab {
  background-color: #fff;
}

.presentation.col {
  cursor: pointer;
}

.card.navigation form.p-fluid {
  background: transparent;
  box-shadow: none;
  padding: 10px;
}

.card.Date h5 {
  width: 51%;
}

.card.Time h5 {
  width: 25%;
}

/* .presentation.col:hover,
.wizardu span.round-tab svg:hover{
  border-bottom: 2px solid #f57800;
  fill: #f57800;
} */


.presentation.col:hover span.round-tab svg {
  color: rgb(85, 73, 148);
}

/* Step 1 End */

/* Step 2  */

.c-stepper__item_2 {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
}


.c-stepper__item_2:not(:last-child):after {
  content: "";
  display: flex;
  height: 2px;
  background-color: #e0e0e0;
}

.c-stepper__item_2:active::before,
.c-stepper__item_2:focus::before,
.c-stepper__item_2:hover::before {
  height: 62px;
  width: 51px;
  background-size: cover;
}


/* Step 2 End */

/* Step 3  */

.c-stepper__item_3 {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
}

.c-stepper__item_3:before {
  content: "";
  display: flex;
  height: 62px;
  width: 51px;
  background-size: cover;
  background-size: cover;
  color: rgb(85, 73, 148);
}

.c-stepper__item_3:active::before,
.c-stepper__item_3:focus::before,
.c-stepper__item_3:hover::before {
  height: 62px;
  width: 51px;
  background-size: cover;
}


/* Step 3 End */

/*** Non-demo CSS ***/
.wrapper {
  max-width: 1000px;
  margin: 2rem auto 0;
}

/* Steps End */

/* Calendar Start */

.p-calendar.p-invalid.p-component>.p-inputtext {
  border-color: #e24c4c;
}

.p-calendar.p-warning.p-component>.p-inputtext {
  border-color: #e79c11;
}

.p-datepicker {
  padding: 0.5rem;
  background: #ffffffa1;
  color: #495057;
  border: 1px solid #e7e7e7;
}

.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #ffffff;
}

.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid #dee2e6;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #343a40;
  border-color: transparent;
  background: #e9ecef;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgb(85, 73, 148)26;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-title select {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
}

.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: transparent;
  border-color: #6366f1;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}

.p-datepicker table {
  font-size: 24px;
  margin: 0.5rem 0;
}

.p-datepicker table th {
  padding: 0.5rem;
}

.p-datepicker table th>span {
  width: 2.5rem;
  height: 2.5rem;
}

.p-datepicker table td {
  padding: 0.5rem;
}

.p-datepicker table td>span {
  width: 2.5rem;
  height: 2.5rem;
  transition: box-shadow 0.2s;
  border: 1px solid transparent;
}

.p-datepicker table td>span.p-highlight {
  color: #495057;
  font-weight: 900;
  background: rgb(85, 73, 148)26;
}

.p-datepicker table td>span:focus {
  outline: 0 none;
  outline-offset: 0;
}

.p-datepicker table td.p-datepicker-today>span {
  background: transparent;
  text-decoration: underline;
  font-weight: 700;
  color: rgb(85, 73, 148);
  text-underline-offset: 1px;
}

.p-datepicker table td.p-datepicker-today>span.p-highlight {
  color: #495057;
  font-weight: 900;
  background: rgb(85, 73, 148)26;
}

.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid transparent;
}

.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}

.p-datepicker .p-timepicker {
  border-top: 1px solid transparent;
  padding: 0.5rem;
}

.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-datepicker .p-timepicker button:enabled:hover {
  color: #343a40;
  background: rgb(85, 73, 148)26;
}

.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: transparent;
}

.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}

.p-datepicker .p-timepicker span {
  font-size: 1.25rem;
}

.p-datepicker .p-timepicker>div {
  padding: 0 0.5rem;
}

.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}

.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: box-shadow 0.2s;
  border-radius: 6px;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #4338ca;
  background: #eef2ff;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: 1px solid #dee2e6;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
  border-right: 0 none;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: rgb(85, 73, 148)26;
  color: #495057;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
  background: rgb(85, 73, 148)26;
  color: #495057;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: transparent;
}


/* Calendar End */

/* Prime Steps */
.p-steps-item {
  font-size: 1em;
  cursor: pointer;
}


.card.circleNavWrapper .p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 3px;
  font-size: 20px;
  font-weight: normal;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 3px;
  font-size: 20px;
  font-weight: bold;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #495057;
  border: 1px solid #e9ecef;
  background: #ffffff;
  min-width: 4rem;
  height: 4rem;
  line-height: 2rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 50%;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: #6c757d;
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffe69c;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #554994;
  color: #fff;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: #495057;
}

.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid #dee2e6;
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}



/* Prime Steps End */

/* Toast Start */

.p-toast {
  opacity: 0.9;
}

.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  border-width: 0 0 0 6px;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 2rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
}

/* Float Label Start */

.p-float-label input:focus~label,
.p-float-label input.p-filled~label,
.p-float-label textarea:focus~label,
.p-float-label textarea.p-filled~label,
.p-float-label .p-inputwrapper-focus~label,
.p-float-label .p-inputwrapper-filled~label {
  font-size: 20px;
  top: -15px;
}

h5.contactInfoHead {
  margin-bottom: 30px;
}

.card.bookSummary {
  margin-top: 45px;
}

.field {
  margin-bottom: 1.5rem;
}

.contactInfo .field {
  margin-bottom: 25px;
  margin-top: 21px;
}

.p-float-label>label {
  left: 0.75rem;
  color: #6c757d;
  transition-duration: 0.2s;
}

.p-float-label label {
  position: absolute;
  pointer-events: none;
  top: 50%;
  margin-top: -0.5rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  font-size: 20px;
}

/* Float Label End */
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
}

.p-toast .p-toast-message .p-toast-icon-close {
  width: 2rem;
  height: 2rem;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: rgba(255, 255, 255, 0.3);
}

.p-toast .p-toast-message .p-toast-icon-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #ffe69c;
}

.p-toast .p-toast-message.p-toast-message-info {
  background: #b3e5fc;
  border: solid #0891cf;
  border-width: 0 0 0 6px;
  color: #044868;
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #044868;
}

.p-toast .p-toast-message.p-toast-message-success {
  background: #c8e6c9;
  border: solid #439446;
  border-width: 0 0 0 6px;
  color: #224a23;
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #224a23;
}

.p-toast .p-toast-message.p-toast-message-warn {
  background: #ffecb3;
  border: solid #d9a300;
  border-width: 0 0 0 6px;
  color: #6d5100;
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #6d5100;
}

.p-toast .p-toast-message.p-toast-message-error {
  background: #ffcdd2;
  border: solid #e60017;
  border-width: 0 0 0 6px;
  color: #73000c;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #73000c;
}

/* Toast End */

/*navigation wizard start*/

.custom .wizard-item {
  padding-left: 25px;
  padding-right: 25px;
}

.custom .nav-item {
  min-width: 110px;
}

.wizard {
  color: #787878;
  background-color: #d1d1d1;
  overflow: hidden;
}

.wizard-item {
  position: relative;
  text-align: center;
  padding-bottom: 0.32421875rem;
  padding-top: 0.32421875rem;
  border-left: 0.15rem solid #fff;
  border-bottom: 2px solid #fff;
  padding-left: 16px;
}

.wizard-item.col {
  cursor: pointer;
}

.rtl .wizard-item {
  border-right: 0.4rem solid #fff;
  padding-right: 30px;
  border-left: 0rem solid transparent;
}

.wizard-item:hover,
.wizard-item:focus {
  color: rgb(85, 73, 148);
  background-color: rgb(85, 73, 148)26;
  text-decoration: none;
}

.wizard-item.disabled,
.wizard-item.disabled:hover,
.wizard-item.disabled:focus {
  color: #b5b5b5;
  background-color: #d1d1d1;
}

.wizard-item.active {
  color: #fff;
  background-color: rgb(85, 73, 148);
}

.wizard-lg>.wizard-item {
  padding-bottom: 0.9rem;
  padding-top: 0.9rem;
  padding-left: 30px;
}

.wizard-lg:not(.rtl)>.wizard-item {
  padding-left: 30px;
}

.wizardu {
  margin: auto;
  background: transparent;
  display: flex;
}

.wizardu .elements {
  position: relative;
  margin: 1% auto;
  margin-bottom: 0;
  width: 100%;
}

.presentation {
  height: 80px;
  margin-bottom: 20px;
  position: relative;
  text-align: center !important;
}

/* span.round-tab {
  width: 100%;
  height: 60px;
  display: flex;
  z-index: 2;
  text-align: center;
  font-size: 25px;
  position: relative;
  justify-content: center;
  flex-direction: row;
} */

span.round-tab svg {
  color: #555555;
  position: relative;
  top: 0.4rem;
}

.wizardu .active span.round-tab {
  background: transparent;
}

.wizardu .active span.round-tab svg {
  color: rgb(85, 73, 148);
}

.wizardu .elements>div a:hover {
  background: transparent;
}

.wizardu .step-description {
  padding-top: 1rem;
}



.wizard-item a span.err-badge {
  position: absolute;
  margin-left: 0.5rem;
}

.err-badge {
  color: #fff;
  background-color: #ff1400;
  border-radius: 10rem;
  padding: 0.2em 0.3rem;
  font-weight: 300;
  margin-left: 0.5rem;
  font-size: 60%;
  z-index: 3;
  position: absolute;
}

.war-badge {
  color: #fff;
  background-color: #e79c11;
  border-radius: 10rem;
  padding: 0.2em 0.3rem;
  font-weight: 300;
  margin-left: 1.5rem;
  font-size: 60%;
  z-index: 3;
  position: absolute;
}

.err-exclamation-mark {
  color: #ff1400;
  margin-left: 0.5rem;
  position: absolute;
}

.err-number {
  color: #ff1400;
  margin-left: 0.5rem;
  position: absolute;
}

.sub-nav-breadcrumb .err-number {
  position: relative;
}

.sub-nav-breadcrumb .err-exclamation-mark {
  position: relative;
}

.sub-nav-breadcrumb .err-badge {
  position: relative;
}

/* .circle-arrow-span {
  content: " ";
  left: 50%;
  opacity: 1;
  margin: 0 auto;
  bottom: -12px;
  margin-left: -10px;
  position: absolute;
  border: 10px solid transparent;
  border-bottom-color: rgb(85, 73, 148);

} */

.sub-nav-breadcrumb>li.active>a,
.sub-nav-breadcrumb>li.active>a:hover,
.sub-nav-breadcrumb>li.active>a:focus {
  font-size: 13px;
  color: rgb(85, 73, 148);
  text-decoration: none;
}

.sub-nav-breadcrumb {
  font-size: 12px;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.sub-nav-breadcrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.sub-nav-breadcrumb>li {
  display: inline-block;
  color: #777777;
  text-decoration: none;
}

.sub-nav-breadcrumb>li+li:before {
  padding: 0 5px;
  color: #ccc;
  content: "/\00a0";
}

.sub-nav-breadcrumb>.active {
  color: #777;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

/*navigation wizard end*/

/* MobileNavIcons Start */

.carousel-demo .product-item .product-item-content {
  margin: 0.3rem;
  text-align: center;
  padding: 2rem 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.carousel-demo .product-item .product-image {
  width: 25%;
}

/* MobileNavIcons End */

/* Captcha IMG Start */

img.center-block.captchaStyle.img-thumbnail.img-rounded.img-responsive {
  width: 100%;
}

/* Captcha IMG End */

div#password {
  margin-bottom: 0;
}

/* Password Field Start */

.p-password.p-invalid.p-component>.p-inputtext {
  border-color: #e24c4c;
}

.p-password-panel {
  padding: 1.25rem;
  background: #ffffff;
  color: #495057;
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.p-password-panel .p-password-meter {
  margin-bottom: 0.5rem;
  background: #dee2e6;
}

.p-password-panel .p-password-meter .p-password-strength.weak {
  background: #ea5455;
}

.p-password-panel .p-password-meter .p-password-strength.medium {
  background: #ff9f42;
}

.p-password-panel .p-password-meter .p-password-strength.strong {
  background: #29c76f;
}

/* Password Field End */

/* HomeLinks IMG */

img.img-thumbnail.img-rounded.img-responsive.imgDesktop {
  height: 80px;
}

button.p-0.select-module-btn.img-rounded.selectModuleDesktop {
  margin-top: 10px;
  border: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-right: 5px;
  cursor: pointer;
}

button.p-0.select-module-btn.img-rounded.selectModuleDesktop:hover {
  box-shadow: 0 3px 6px #f57800, 0 3px 6px #f57800;
}

img.img-thumbnail.img-rounded.img-responsive.imgDesktop {
  margin-top: 10px;
}

h5 {
  padding: 0;
  margin: 10px;
  font-family: kurdish, "adobe-arabic", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
}

.col-12.md\:col-6.center {
  display: flex;
  justify-content: center;
}

.pWithShadow {
  margin-top: 50px;
  font-family: kurdish, "adobe-arabic", sans-serif;
  font-style: normal;
  font-weight: 400;
  padding: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: #ffffff47;
}

.pWithShadow2 {
  margin-top: 30px;
  font-family: kurdish, "adobe-arabic", sans-serif;
  font-style: normal;
  font-weight: 400;
  padding: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: #ffffff47;
}

button.p-0.select-module-btn.img-rounded.selectModuleDesktop {
  width: 100%;
  height: 100%;
}

/* HomeLinks IMG */

/* Footer */
.footer {
  border-top: 1px solid rgb(85, 73, 148);
}

.xl\:col-2.logoFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.xl\:col-9.menuFooter {
  display: flex;
  justify-content: flex-end;
}

.xl\:col-1.socialFooter {
  display: flex;
  align-items: center;
  justify-content: center;
}

div#copyright-text {
  font-size: 16px;
  margin-left: 5px;
}

div#social-buttons .pi {
  margin-right: 10px;
  font-size: 18px;
  color: rgb(85, 73, 148);
}

div#card\ footer {
  padding: 12px;
  position: absolute;
  width: 98.7%;
  border-top: 2px solid #c5c5c5;
}

.xl\:col-2.logoFooter img {
  width: 68px;
}

div#social-buttons .pi:hover {
  color: #f57800;
}

div#card\ footer .p-menubar .p-submenu-list {
  bottom: 50px;
  z-index: 5;
}

div#card\ footer span.p-submenu-icon.pi.pi-angle-down {
  transform: rotate(180deg);
}

.card li.p-menuitem.p-menuitem-active span.p-submenu-icon.pi.pi-angle-down {
  transform: rotate(180deg);
}

/* Footer End */

/* dev menu */
.devMenu {
  background-color: #ccc;
}

.p-fileupload-content>.p-progressbar {
  z-index: 5;
  width: 100%;
  top: 0px;
  left: 0;
  bottom: 9px;
  margin-bottom: 15px;
}

button.p-button.p-component.p-button-icon-only {
  width: 3rem;
}

.p-fileupload-files {
  margin-top: 7px;
  padding-top: 17px;
}

.padding-10 {
  padding: 10%;
}

.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 0%;
  height: 3rem;
}

.p-button.p-button-danger.p-button-outlined {
  background-color: transparent;
  color: #ff1400;
}

.p-button.p-button-danger.p-button-outlined:enabled:hover {
  background: #ff1400;
  color: #fff;
  border: 1px solid transparent;
}


.p-multiselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 8px;
  margin-top: 1.2%;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: rgb(85, 73, 148)26;
}

.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgb(85, 73, 148)26;
  border-color: rgb(85, 73, 148)26;
}

.p-multiselect.p-multiselect-clearable .p-multiselect-label {
  padding-right: 1.75rem;
}

.p-multiselect .p-multiselect-label {
  padding: 8px 8px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: #6c757d;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  background: #EEF2FF;
  color: #4338CA;
  border-radius: 6px;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.5rem;
}

.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #6c757d;
  width: 3rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-multiselect .p-multiselect-clear-icon {
  color: #6c757d;
  right: 3rem;
}

.p-multiselect.p-invalid.p-component {
  border-color: #e24c4c;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.375rem 0.75rem;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label.p-multiselect-items-label {
  padding: 0.5rem 0.75rem;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-clearable .p-multiselect-label {
  padding-right: 1.75rem;
}

.p-multiselect-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem 1.25rem;
  border-bottom: 1px solid #dee2e6;
  color: #343a40;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
  margin: 0 0.5rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 1.75rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 0.75rem;
  color: #6c757d;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #343a40;
  border-color: transparent;
  background: #e9ecef;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: transparent;
}

.p-multiselect-panel .p-multiselect-items {
  padding: 0.5rem 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.5rem 1.25rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #4338CA;
  background: rgb(85, 73, 148)26;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem transparent;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  margin: 0;
  padding: 0.5rem 1.25rem;
  color: #343a40;
  background: #ffffff;
  font-weight: 700;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 0.5rem 1.25rem;
  color: #495057;
  background: transparent;
}

.p-input-filled .p-multiselect {
  background: #e9ecef;
}

.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #e9ecef;
}

.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #ffffff;
}

/* top header line start */
.top-header-line {
  background-color: transparent;
}

.top-header-line .grid {
  margin: 0
}

.top-header-line .p-selectbutton .p-button {
  height: auto;
  padding: 1%;
  background-color: transparent;
}

.top-header-line .p-button.p-component.p-highlight {
  background-color: transparent;
  text-decoration: underline;
  color: black;
  font-weight: bold;
  text-underline-position: under;
  text-decoration-thickness: 2px;
  font-size: 20px !important;
}

.top-header-line .p-selectbutton .p-button:hover {
  background-color: transparent;
  text-decoration: underline;
  color: black;
  font-weight: bold;
  text-underline-position: under;
  text-decoration-thickness: 2px;
}


.top-header-line .p-selectbutton img {
  width: 30px;
  height: auto;
}

/* top header line end */

.formWidth {
  width: 70%;
}

.suppDocStyle {
  width: 70%;
}

.docData {
  margin-bottom: 20px;
}

.documentFields {
  margin-bottom: 2rem;
}

.itemMargin {
  margin-bottom: 10px;
}

/* Scroll To Top Start */

.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-scrolltop.p-link {
  background: rgb(85, 73, 148);
}

.p-scrolltop.p-link:hover {
  background: rgb(78 67 137);
}

.p-scrolltop .p-scrolltop-icon {
  font-size: 1.5rem;
  color: #ffffff;
}


/* Scroll To Top End */

/* Skeleton Start */

.p-skeleton {
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.p-skeleton:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

/* Skeleton End */


/* Chip Start */

.p-chip {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 16px;
  padding: 0 1rem;
}

.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.p-chip .p-chip-icon {
  margin-right: 0.5rem;
}

.p-chip img {
  width: 2.5rem;
  height: 2.5rem;
  margin-left: -1rem;
  margin-right: 0.5rem;
}

.p-chip .p-chip-remove-icon {
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-left: 0.5rem;
}

.p-chip .p-chip-remove-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

/* Chip End */


/* Avatar Group Start */

.p-avatar {
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
}

.p-avatar.p-avatar-lg .p-avatar-icon {
  font-size: 1.5rem;
}

.p-avatar.p-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
}

.p-avatar.p-avatar-xl .p-avatar-icon {
  font-size: 2rem;
}

.p-avatar-group .p-avatar {
  border: 2px solid #ffffff;
}

/* Avatar Group End */


/* Radio Button Start */

.p-radiobutton {
  width: 20px;
  height: 20px;
}

.p-radiobutton .p-radiobutton-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: #212529;
  border-radius: 50%;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #ced4da;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #009eac54;
  border-color: rgb(85, 73, 148);
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px;
  height: 12px;
  transition-duration: 0.15s;
  background-color: #ffffff;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: rgb(85, 73, 148);
  background: rgb(85, 73, 148);
  box-shadow: 0 0 0 0.2rem #dee2e6;

}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: rgb(78 67 137);
  background: rgb(78 67 137);
  color: #ffffff;
}

.p-radiobutton.p-invalid>.p-radiobutton-box {
  border-color: #dc3545;
}

.p-radiobutton:focus {
  outline: 0 none;
}

.field-checkbox,
.field-radiobutton {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.field.checkBoxQuest label {
  margin-left: 0.2rem;
  line-height: 1;
  margin-top: 1rem;
}


/* Radio Button End */


/* Autocomplete Start */

.p-autocomplete .p-autocomplete-loader {
  right: 0.75rem;
}

.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 3.107rem;
}

.p-autocomplete .p-autocomplete-multiple-container {
  padding: 0.25rem 0.75rem;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: #3f4b5b;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px #e3f3fe;
  border-color: #8dd0ff;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0.25rem 0;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family: kurdish, adobe-arabic, sans-serif;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.87);
  padding: 0;
  margin: 0;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  background: rgb(85, 73, 148);
  color: #fff;
  border-radius: 4px;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  margin-left: 0.5rem;
}

.p-autocomplete.p-invalid.p-component>.p-inputtext {
  border-color: #f19ea6;
}

.p-autocomplete-panel {
  background: #fff;
  color: #495057;
  border: 1px solid rgb(85, 73, 148);
  border-radius: 4px;
  box-shadow: none;
}

.p-autocomplete-panel .p-autocomplete-items {
  padding: 0.5rem 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.15s;
  border-radius: 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: #495057;
  font-weight: bold;
  background: rgb(85, 73, 148)26;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: #fff;
  background: rgb(85, 73, 148);
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
  margin: 0;
  padding: 0.5rem 1rem;
  color: rgba(255, 255, 255, 0.87);
  background: #2a323d;
  font-weight: 600;
}

/* Autocopmplete End */

/* Steps Arrow Start */

.card.navigation.arrowWrapper .grid .col-12 .p-steps .p-steps-item {
  display: inline-block;
  position: relative;
}

.card.navigation.arrowWrapper .grid .col-12 .p-steps ul {
  display: flex;
  position: relative;
}

.card.navigation.arrowWrapper .grid .col-12 .p-steps .p-steps-item {
  display: flex;
  position: relative;
  height: 6vh;
  width: 13vw;
  padding: 0 10px 0 40px;
  font-size: 20px;
  text-decoration: none;
  color: rgb(85, 73, 148);
  border-top: 1px solid rgb(85, 73, 148);
  border-bottom: 1px solid rgb(85, 73, 148);
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
}

.card.navigation.arrowWrapper .grid .col-12 .p-steps .p-steps-item:first-child {
  border-radius: 4px 0 0 5px;
  border-left: 1px solid rgb(85, 73, 148);
}

.card.navigation.arrowWrapper .grid .col-12 .p-steps .p-steps-item:last-of-type {
  border-radius: 0 4px 4px 0;
  border-right: 1px solid rgb(85, 73, 148);

}

.card.navigation.arrowWrapper .grid .col-12 .p-steps .p-steps-item:last-of-type::after {
  display: none;
}

.card.navigation.arrowWrapper .grid .col-12 .p-steps .p-steps-item::after {
  content: "";
  position: absolute;
  top: 2.89vh;
  right: -1vw;
  z-index: 2;
  transform: translateY(-50%) rotate(225deg);
  width: 2.01vw;
  height: 4.1vh;
  border-top: 0;
  border-left: 1px solid rgb(85, 73, 148);
  border-bottom: 1px solid rgb(85, 73, 148);
  border-radius: 0;
}

.card.navigation.arrowWrapper .grid .col-12 .p-steps .p-steps-item::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 1.6vh;
  left: -1px;
  z-index: 1;
  border-left: 2.8vh solid white;
  border-top: 3.2vh solid transparent;
  border-bottom: 2.8vh solid transparent;
}

.card.navigation.arrowWrapper .grid .col-12 .p-steps .p-steps-item:first-child::before {
  display: none;
}

.card.navigation.arrowWrapper .grid .col-12 .p-steps .p-steps-item:first-child::after {
  content: "";
  position: absolute;
  top: 2.89vh;
  right: -1vw;
  z-index: 2;
  transform: translateY(-50%) rotate(225deg);
  width: 2.01vw;
  height: 4.1vh;
  border-top: 0;
  border-left: 1px solid rgb(85, 73, 148);
  border-bottom: 1px solid rgb(85, 73, 148);
  border-radius: 0;
}

.card.navigation.arrowWrapper .grid .col-12 .p-steps .p-steps-item:hover:after {
  background: #abdfe4;
}

.card.navigation.arrowWrapper .grid .col-12 .p-steps .p-steps-item.p-highlight.p-steps-current::after {
  background: #abdfe4;
}

.card.navigation.arrowWrapper .grid .col-12 .p-steps .p-steps-item.p-highlight.p-steps-current:first-child::after {
  background: #abdfe4;
}


.card.navigation.arrowWrapper .grid .col-12 .p-steps .p-steps-item:hover {
  background: #009eac54;
  color: black;
}

.card.navigation.arrowWrapper .grid .col-12 .p-steps li.p-steps-item.p-highlight.p-steps-current:first-child:hover::after {
  background: #abdfe4;
}

.card.navigation.arrowWrapper .grid .col-12 .p-steps li.p-steps-item.p-highlight.p-steps-current:hover::after {
  background: #abdfe4;
}

.card.navigation.arrowWrapper .grid .col-12 .p-steps li.p-steps-item.p-highlight.p-steps-current {
  background-color: #009eac54;
}

.card.navigation.arrowWrapper .grid .col-12 .p-steps li.p-steps-item.p-highlight.p-steps-current .step-arrow-container span i {
  font-weight: bold;
}

.card.navigation.arrowWrapper .grid .col-12 .p-steps li.p-steps-item.p-highlight.p-steps-current .step-arrow-container span {
  color: black;
  font-weight: bold;
  font-size: 14px;
}

.step-arrow-container {
  z-index: 10000;
  position: inherit;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.card.navigation.arrowWrapper .grid .col-12 .p-steps .p-steps-item {
  box-shadow: none;
  padding: 0;
}

.card.navigation.arrowWrapper .card.warningBtnWrapper {
  display: flex;
  justify-content: flex-end;
}

/* Steps Arrow End */

/* Steps Start */


.card.circleNavWrapper .p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: box-shadow 0.15s;
  border-radius: 4px;
}

.card.circleNavWrapper .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: #6c757d;
}

.card.circleNavWrapper .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #009eac54;
}

.card.circleNavWrapper .p-steps .p-steps-item.p-highlight .p-steps-number {
  background: rgb(85, 73, 148);
  color: #ffffff;
}

.card.circleNavWrapper .p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: #212529;
}

.card.circleNavWrapper .p-steps li.p-steps-item.p-highlight.p-steps-current {
  background-color: transparent !important;
}

.card.circleNavWrapper .p-steps .p-steps-item {
  border-radius: none !important;
  border-left: none !important;
  border-top: none !important;
  border-bottom: none !important;
}

.card.circleNavWrapper .p-steps .p-steps-item.p-highlight.p-steps-current:first-child::after {
  background: transparent !important;
}

.card.circleNavWrapper .grid .col-12 .p-steps li.p-steps-item.p-highlight.p-steps-current {
  background: transparent !important;
}

.card.circleNavWrapper .p-steps .p-steps-item:before {
  content: " " !important;
  border-top: 1px solid #dee2e6 !important;
  width: 100% !important;
  top: 24px !important;
  left: 0 !important;
  display: block !important;
  position: absolute !important;
  margin-top: -1rem !important;
}

.card.circleNavWrapper .p-steps .p-steps-item:hover {
  background: transparent !important;
}

.card.circleNavWrapper .p-steps .p-steps-item .p-menuitem-link {
  background-color: transparent;
}

.card.circleNavWrapper .p-steps .p-steps-item:first-child::after {
  border-left: none !important;
  border-bottom: none !important;
}

.card.circleNavWrapper .grid .col-12 .p-steps .p-steps-item::after {
  border-left: none !important;
  border-bottom: none !important;
}

.card.circleNavWrapper .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  min-width: 1rem;
  height: 1rem;
  font-size: 1rem;
  color: #d9d9d9;
  background: transparent;
  border: transparent;
}

.card.circleNavWrapper .p-steps.p-component {
  width: 50%;
}

.card.circleNavWrapper .p-steps .p-steps-item:last-of-type {
  border-right: none !important;
}

.card.circleNavWrapper .p-steps .p-steps-item:hover:after {
  background: transparent !important
}

.card.circleNavWrapper .p-steps .p-steps-item.p-highlight .p-steps-number {
  background: transparent;
  color: #554994;
}

.card.circleNavWrapper .card.warningBtnWrapper {
  position: absolute;
  top: 32%;
  right: 4%;
}

.card.circleNavWrapper .grid .col-12 .p-steps .p-steps-item::before {
  border-left: none !important;
  border-bottom: none !important;
}

.card.circleNavWrapper .p-steps .p-steps-item.p-highlight.p-steps-current::after {
  background: transparent !important;
}



/* Steps End */

/* Tabmenu Start */

.p-tabmenu .p-tabmenu-nav {
  background: transparent;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: solid;
  border-width: 1px;
  border-color: #ffffff #ffffff #dee2e6 #ffffff;
  background: #ffffff;
  color: #6c757d;
  padding: 0.5rem 1rem;
  font-weight: 600;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: box-shadow 0.15s;
  margin: 0 0 -1px 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #009eac54;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: #ffffff;
  border-color: #dee2e6;
  color: #6c757d;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff #dee2e6;
  color: #495057;
}

/* Tab menu End */

/* Tiered menu Start */

.p-tieredmenu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: #212529;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  width: 12.5rem;
}

.p-tieredmenu .p-menuitem-link {
  padding: 0.5rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: #212529;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}

.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: #212529;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}

.p-tieredmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #009eac54;
}

.p-tieredmenu.p-tieredmenu-overlay {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}

.p-tieredmenu .p-submenu-list {
  padding: 0.5rem 0;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}

.p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link {
  background: #e9ecef;
}

.p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text {
  color: #212529;
}

.p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-tieredmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon {
  color: #212529;
}

.p-tieredmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}

.p-tieredmenu .p-submenu-icon {
  font-size: 0.875rem;
}

/* Tiered menu End */


/* Mega Menu Start */

.p-megamenu {
  padding: 0.5rem 1rem;
  background: #efefef;
  color: rgba(0, 0, 0, 0.9);
  border: 0 none;
  border-radius: 4px;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link {
  padding: 1rem;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  transition: box-shadow 0.15s;
  user-select: none;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.5);
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.5);
  margin-right: 0.5rem;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 0.5rem;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover {
  background: transparent;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.7);
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.7);
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.7);
}

.p-megamenu .p-megamenu-root-list>.p-menuitem>.p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #009eac54;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link,
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover {
  background: transparent;
}

.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text,
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.9);
}

.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.9);
}

.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon,
.p-megamenu .p-megamenu-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.9);
}

.p-megamenu .p-menuitem-link {
  padding: 0.5rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}

.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: #212529;
}

.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}

.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: #212529;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}

.p-megamenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #009eac54;
}

.p-megamenu .p-megamenu-panel {
  background: #ffffff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}

.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.5rem 1rem;
  color: #212529;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-megamenu .p-megamenu-submenu {
  padding: 0.5rem 0;
  width: 12.5rem;
}

.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}

.p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link {
  background: #e9ecef;
}

.p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text {
  color: #212529;
}

.p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon {
  color: #212529;
}

.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: 0.5rem 0;
}

/* Mega menu end */

/* Chips Start */

.p-chips .p-chips-multiple-container {
  padding: 0.25rem 0.75rem;
}

.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: #ced4da;
}

.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #009eac54;
  border-color: rgb(85, 73, 148);
}

.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  background: rgb(85, 73, 148);
  color: #ffffff;
  border-radius: 4px;
}

.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0.25rem 0;
}

.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: kurdish, adobe-arabic, sans-serif;
  font-size: 1rem;
  color: #212529;
  padding: 0;
  margin: 0;
}

.p-chips.p-invalid.p-component>.p-inputtext {
  border-color: #dc3545;
}

/* Chips End */


/* Color Picker Start */

.p-colorpicker-preview {
  width: 2rem;
  height: 2rem;
}

.p-colorpicker-panel {
  background: #212529;
  border-color: #212529;
}

.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
  border-color: #ffffff;
}

.p-colorpicker-overlay-panel {
  box-shadow: none;
}

/* Color Picker End */

/* Rating Start */

.p-rating .p-rating-icon {
  color: #495057;
  margin-left: 0.5rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  font-size: 1.143rem;
}

.p-rating .p-rating-icon.p-rating-cancel {
  color: #dc3545;
}

.p-rating .p-rating-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #009eac54;
}

.p-rating .p-rating-icon:first-child {
  margin-left: 0;
}

.p-rating .p-rating-icon.pi-star-fill {
  color: rgb(85, 73, 148);
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: rgb(85, 73, 148);
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
  color: #dc3545;
}

.p-highlight .p-rating .p-rating-icon {
  color: #ffffff;
}

/* Rating End */

/* Toggle button Start */

.p-togglebutton.p-button {
  background: #6c757d;
  border: 1px solid #6c757d;
  color: #ffffff;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
  color: #ffffff;
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #5a6268;
  border-color: #545b62;
  color: #ffffff;
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #ffffff;
}

.p-togglebutton.p-button.p-highlight {
  background: rgb(85, 73, 148);
  border-color: rgb(85, 73, 148);
  color: #ffffff;
}

.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}

.p-togglebutton.p-button.p-highlight:hover {
  background: #545b62;
  border-color: #4e555b;
  color: #ffffff;
}

.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}

.p-togglebutton.p-button.p-invalid>.p-button {
  border-color: #dc3545;
}


/* Toggle button End */


/* Input Group Start */

.p-inputgroup-addon {
  background: #e9ecef;
  color: #495057;
  border-top: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  padding: 0.5rem 0.75rem;
  min-width: 2.357rem;
}

.p-inputgroup-addon:last-child {
  border-right: 1px solid #ced4da;
}

.p-inputgroup>.p-component,
.p-inputgroup>.p-inputwrapper>.p-inputtext,
.p-inputgroup>.p-float-label>.p-component {
  border-radius: 0;
  margin: 0;
}

.p-inputgroup>.p-component+.p-inputgroup-addon,
.p-inputgroup>.p-inputwrapper>.p-inputtext+.p-inputgroup-addon,
.p-inputgroup>.p-float-label>.p-component+.p-inputgroup-addon {
  border-left: 0 none;
}

.p-inputgroup>.p-component:focus,
.p-inputgroup>.p-inputwrapper>.p-inputtext:focus,
.p-inputgroup>.p-float-label>.p-component:focus {
  z-index: 1;
}

.p-inputgroup>.p-component:focus~label,
.p-inputgroup>.p-inputwrapper>.p-inputtext:focus~label,
.p-inputgroup>.p-float-label>.p-component:focus~label {
  z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup>.p-inputwrapper:first-child,
.p-inputgroup>.p-inputwrapper:first-child>.p-inputtext {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup>.p-inputwrapper:last-child,
.p-inputgroup>.p-inputwrapper:last-child>.p-inputtext {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}

.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 2.357rem;
}

/* Input Group End */


/* Toolbar Start */

.p-toolbar {
  background: transparent;
  border: transparent;
  padding: 1rem 1.25rem;
  border-radius: 4px;
}

.p-toolbar .p-toolbar-separator {
  margin: 0 0.5rem;
}

/* Toolbar End */

/* Breadcrumb Start */

.p-breadcrumb {
  background: #efefef;
  border: 0 none;
  border-radius: 4px;
  padding: 1rem;
}

.p-breadcrumb ul li .p-menuitem-link {
  transition: box-shadow 0.15s;
  border-radius: 4px;
}

.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #009eac54;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: rgb(85, 73, 148);
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: rgb(85, 73, 148);
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5rem 0 0.5rem;
  color: #6c757d;
}

.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: #6c757d;
}

.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: #6c757d;
}

/* Breadcrumb End */


/* Context menu */

.p-contextmenu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  border-radius: 4px;
  width: 12.5rem;
}

.p-contextmenu .p-menuitem-link {
  padding: 0.5rem 1rem;
  color: #212529;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}

.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: #212529;
}

.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: #212529;
  margin-right: 0.5rem;
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: #212529;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #212529;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #212529;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #212529;
}

.p-contextmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #009eac54;
}

.p-contextmenu .p-submenu-list {
  padding: 0.5rem 0;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  border-radius: 4px;
}

.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link {
  background: #e9ecef;
}

.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-text {
  color: #212529;
}

.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-menuitem-icon,
.p-contextmenu .p-menuitem.p-menuitem-active>.p-menuitem-link .p-submenu-icon {
  color: #212529;
}

.p-contextmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}

.p-contextmenu .p-submenu-icon {
  font-size: 0.875rem;
}

/* Context Menu End */

/* Divider Start */

.p-divider .p-divider-content {
  background-color: #ffffff;
}

.p-divider.p-divider-horizontal {
  margin: 1rem 0;
  padding: 0 1rem;
}

.p-divider.p-divider-horizontal:before {
  border-top: 1px #dee2e6;
}

.p-divider.p-divider-horizontal .p-divider-content {
  padding: 0 0.5rem;
}

.p-divider.p-divider-vertical {
  margin: 0 1rem;
  padding: 1rem 0;
}

.p-divider.p-divider-vertical:before {
  border-left: 1px #dee2e6;
}

.p-divider.p-divider-vertical .p-divider-content {
  padding: 0.5rem 0;
}

/* Divider End */

/* File Upload Start */

.p-fileupload .p-fileupload-buttonbar {
  background: #efefef;
  padding: 1rem 1.25rem;
  border: 1px solid #dee2e6;
  color: #212529;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
}

.p-fileupload .p-fileupload-content {
  background: #ffffff;
  padding: 2rem 1rem;
  border: 1px solid #dee2e6;
  color: #212529;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-fileupload .p-progressbar {
  height: 0.25rem;
}

.p-fileupload .p-fileupload-row>div {
  padding: 1rem 1rem;
}

.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}


/* File Upload End */

/* Progressbar Start */

.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: #e9ecef;
  border-radius: 4px;
}

.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: rgb(85, 73, 148);
}

.p-progressbar .p-progressbar-label {
  color: white;
  line-height: 1.5rem;
}

/* Progressbar End */


/* Tag Start */

.p-tag {
  background: rgb(85, 73, 148);
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 4px;
}

.p-tag.p-tag-success {
  background-color: #28a745;
  color: #ffffff;
}

.p-tag.p-tag-info {
  background-color: #17a2b8;
  color: #ffffff;
}

.p-tag.p-tag-warning {
  background-color: #ffc107;
  color: #212529;
}

.p-tag.p-tag-danger {
  background-color: #dc3545;
  color: #ffffff;
}

.p-virtualscroller-loading-icon {
  font-size: 2rem;
}

/* Tag Start */

/* Banner Start */

.text-gray-100.p-3.flex.justify-content-between.lg\:justify-content-center.align-items-center.flex-wrap {
  background-color: rgb(85, 73, 148);
}

/* Banner End */

/* Timeline Start */

.p-timeline .p-timeline-event-marker {
  border: 0 none;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: rgb(85, 73, 148);
}

.p-timeline .p-timeline-event-connector {
  background-color: #dee2e6;
}

.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0 1rem;
}

.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 2px;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
  padding: 1rem 0;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
  height: 2px;
}

/* Timeline End */


/* Picklist Start */

.p-picklist .p-picklist-buttons {
  padding: 1.25rem;
}

.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
}

.p-picklist .p-picklist-header {
  background: #efefef;
  color: #212529;
  border: 1px solid #dee2e6;
  padding: 1rem 1.25rem;
  font-weight: 600;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-picklist .p-picklist-list {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #212529;
  padding: 0.5rem 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.5rem 1.5rem;
  margin: 0;
  border: 0 none;
  color: #212529;
  background: transparent;
  transition: transform 0.15s, box-shadow 0.15s;
}

.p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
  background: #e9ecef;
  color: #212529;
}

.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #009eac54;
}

.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: #ffffff;
  background: rgb(85, 73, 148);
}


/* Picklist End */

/* Orderlist Start */

.p-orderlist .p-orderlist-controls {
  padding: 1.25rem;
}

.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}

.p-orderlist .p-orderlist-header {
  background: #efefef;
  color: #212529;
  border: 1px solid #dee2e6;
  padding: 1rem 1.25rem;
  font-weight: 600;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-orderlist .p-orderlist-list {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #212529;
  padding: 0.5rem 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.5rem 1.5rem;
  margin: 0;
  border: 0 none;
  color: #212529;
  background: transparent;
  transition: transform 0.15s, box-shadow 0.15s;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
  background: #e9ecef;
  color: #212529;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #009eac54;
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #ffffff;
  background: rgb(85, 73, 148);
}


/* Orderlist End */


/*Custom Button Icons Start*/


button.p-button.p-component.p-button-rounded.p-button-text.btnCompImg.p-button-icon-only {
  width: 130px;
  height: 160px;
}

button.p-button.p-component.p-button-rounded.p-button-text.btnCompImg {
  display: flex;
  flex-direction: column;
}

.docImageWrap {
  width: 125px;
  height: 151px;
}

.docFlex {
  display: flex !important;
  align-items: center !important;
}

button.p-button.p-component.p-button-rounded.p-button-text.btnCompImg:hover {
  background: rgb(85 73 148 / 12%);
  color: rgb(85, 73, 148);
}

button.p-button.p-component.p-button-rounded.p-button-text.btnCompImg span.p-button-label.p-c {
  margin-top: 5%;
}

.col-12.btnImgTextWrap {
  display: flex;
  justify-content: space-evenly;
  padding: 1.5rem;
}

/*Custom Button Icons End */

/* Driver License Task Start */

.task-container.step-container.row.col-8 {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0;
}

/* Driver License Task End */


/* Questionnarie Radio Button Start */

.p-radiobutton.p-component.p-radiobutton-checked.radioQuest .p-radiobutton-box.p-highlight .p-radiobutton-icon {
  transform: translateZ(0) scale(0, 0);
}

.input-radiobutton label {
  top: 3px;
  position: relative;
}

.p-radiobutton.p-component.radioQuest {
  margin-left: 25px;
  margin-top: 5px;
}


.additionalQuestLabel {
  font-size: 20px;
  color: #696cff;
}

.field.checkBoxQuest {
  margin-left: 50%;
  margin-top: -3%;
}

.field-checkbox.additionalQuestLabel {
  margin-left: 25px;
}

.field-radiobutton label {
  color: #495057;
}

.additionalQuestionLabel {
  color: #696cff;
  font-size: 15px;
  margin-left: 25px;

}

.checkBoxQuestLeft {
  margin-left: 25px;

}

.additionalQuestionsWrapper {
  margin-left: 25px;

}

.radionInlineWrapForQuest {
  margin-bottom: 2.5rem;
}

/* Questionnarie Radio Button End */

/*Questionnaire header */

.useCaseHeader {
  text-align: right;
  margin-bottom: -30px;
  margin-right: 10px;
}

.useCaseHeader h5 {
  margin: 0;
}

/*Questionnaire header end */

/* Admin Account Page Start */

.grid.buttonProcessHistoryWrap {
  display: flex;
  justify-content: flex-end;
  margin-right: 1%;
}

.grid.adminAccWrap {
  box-shadow: 7px 7px 16px 7px #888888;
  padding: 1%;
  display: flex;
  margin-left: 2rem;
  margin-right: 2rem;
  background-color: #ededed91;
}

.grid.buttonProcessHistoryWrap {
  margin-bottom: 1%;
  margin-right: 2rem;
}

.grid.main-button-bar.adminAccBtns {
  display: flex;
  justify-content: space-between;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 1rem;
}

h1.adminAccOverview {
  margin-left: 2rem;
  margin-bottom: 0;
}

h4.h4AdminAcc {
  margin-bottom: 0;
  font-size: 20px;

}

ul.list-group.listUL {
  list-style: none;
  padding-left: 0;
}

li.list-group-item.listText {
  font-weight: 700;
}

li.list-group-item.listText span {
  font-weight: 400;
}

.grid.personalInfoAdminAcc {
  display: flex;
  flex-direction: column;
}

li.list-group-item.listText {
  border-bottom: 1px solid #8d8d8dc9;
  margin-bottom: 2%;
}

/* Admin Account Page End */

/* step circle for mobile start*/


.CircularProgressbar {
  height: 100px;
  width: auto !important
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: rgb(85, 73, 148) !important;
}

.mobileProgresComponent {
  display: flex;
}

.step-invalid {
  border: 5px solid #ff0000 !important;
}

.step-completed {
  border: 5px solid #15b738 !important;
}

.text-red {
  color: red
}

/* step circle for mobile  end*/


img.mb-3 {
  width: 100px;
  height: 100px;
}

.mb-3 {
  margin-bottom: 0.1rem;
  display: flex;
  justify-content: center;
}

.mb-3 .docImageWrap {
  height: auto;
  width: 80px;
}

.grid.text-center.succReg {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.grid.text-center.succReg {
  padding: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  background: #f8f9faab;
  box-shadow: 3px 7px 9px 2px #8888885e;
  align-content: center;
}

.p-menuitem .p-highlight {
  background: rgb(85, 73, 148);
}

.p-menuitem .p-highlight a.p-menuitem-link span.p-menuitem-text {
  color: white !important;
}

.col-6.text-right.localeWrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.localeIconWrapper {
  position: relative;
  width: 80px;
  display: flex;
  justify-content: space-around;
}

i.mr-4.ml-4.pi.pi-moon {
  cursor: pointer;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.checkBoxesCustomWrap.grid {
  padding-left: 4rem;
  padding-top: 2rem;
}

.checkBoxesCustomWrap.col-3 label {
  margin-left: 0.3rem;
}

.checkBoxesCustomWrap.col-3 {
  padding: 0;
}

.field.checkBoxQuestLeft {
  margin-bottom: 0.5rem;
}

.card.Time .p-button.p-component {
  width: 9vh;
}

.grid.plateNumberWrap {
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: flex-end;
}

.grid.captchaWrap.md\:col-2.xl\:col-2 {
  padding: 0;
}

.grid.auctionNumberWrap .field.checkBoxQuestLeft {
  margin-top: 1rem;
}

.grid.captchaWrap.md\:col-3.xl\:col-3 .outerCaptchaBlockClassFormatted .md\:col {
  padding: 0;
}

.plateNumberField.md\:col-3.xl\:col-3 .field {
  margin-bottom: 0;
}

.grid.captchaWrap.md\:col-3.xl\:col-3 .outerCaptchaBlockClassFormatted .grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.grid.oldPlateDetailsWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

h5.oldVehiclePlateDetailsTaskLabel {
  display: flex;
  justify-content: flex-start;
  top: -15px;
  position: relative;
  margin-left: 0;
}

.plateButtonsWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.checkPlateButton {
  padding-left: 0;
  top: -16px;
  position: relative;
}

.reservePlate {
  top: 24px;
  position: relative;
}

.checkPlateButton button.p-button.p-component.p-button-success {
  width: 203px;
}

.grid.captchaWrap.md\:col-3.xl\:col-3 .outerCaptchaFieldClassFormatted {
  width: 390px;
}

.grid.auctionNumberWrap {
  top: 39px;
  position: relative;
  margin-right: auto;
  padding-left: 12px;
}

.grid.auctionNumberWrap .field.checkBoxQuestLeft {
  margin-left: 0;
}


.headerLogo {
  text-decoration: none;
}

.tagline {
  margin-right: 10px;
  margin-left: 10px;
  color: black;
  font-weight: 700;
}

.logoWithTagline {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.headerLogoTitle {
  text-decoration: none;
  color: #554994;
  font-size: 20px;
  font-weight: 600;
}

.plateIllustrationWrapper {
  width: 80%;
  margin-left: auto;
}

.col-12.flex.justify-content-center.captchaAndPlateWrap {
  display: flex;
  align-items: center;
}

.col-6.checkPlateBtn {
  display: flex;
  justify-content: flex-end;
}

.p-datepicker.p-component {
  z-index: 100000 !important;
}

.displayLangMenu {
  animation-name: displayLangMenu;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
  position: absolute;
  width: 100px;
  left: -20px;
  top: 30px;
  background: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  box-shadow: 1px 1px 5px #c7c7c7;
  flex-direction: column;
  z-index: 5;
}

@keyframes displayLangMenu {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.col-9.xl\:col-2.logoFooter img {
  width: 40px;
}

i.pi.pi-globe {
  cursor: pointer;
}

i.mr-4.ml-4.pi.pi-moon {
  cursor: pointer;
}

i.mr-4.ml-4.pi.pi-sun {
  cursor: pointer;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.flex-center {
  display: flex;
  justify-content: center;
}

span.block.text-6xl.font-bold.mb-1 {
  font-size: 100px !important;
}

.font-bold {
  font-weight: bold !important;
}

.text-6xl.text-primary.font-bold.mb-3 {
  font-size: 50px !important;
}

svg#myAppIcon path.st3 {
  fill: none;
  stroke: rgb(85, 73, 148);
  stroke-width: 20px;
}

svg#myAppIcon path {
  fill: #554994;
}

.plateIllustrationWrapper .cls-27 {
  font-family: 'adobe-arabic';
  font-size: 200px;
}

.col-lg-12.col-sm-12 button.p-button.p-component.mt-2.p-button-secondary {
  margin-right: 1%;
}

.card.circleNavWrapper .p-steps-number.step-invalid {
  color: red !important;
  border: none !important;
}

.flex.justify-content-center.protectedWrap {
  width: 100%;
}

.flex.justify-content-center.protectedWrap .plateIllustrationWrapper {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.plateIllustrationWrapper {
  direction: ltr !important;
}

.publicWrap {
  width: 100%;
}

.buttonsHomeWrap.flex {
  display: flex;
  justify-content: center;
}

p-button-blue{
  background: #1d70a5 !important;
  border-color: #1d70a5 !important;
}

p-button-blue:hover{
  background: #1A5982FF !important;
  border-color: #1A5982FF !important;
}

.outerCaptchaBlockClassFormatted button.p-button {
  border-radius: 8px !important;
}

.outerCaptchaBlockClassFormatted .grid {
  align-items: center;
}

.navButtonsLocal .grid .navButtonsLocationOne {
  text-align: left !important;
}

.navButtonsLocal .grid .navButtonsLocationTwo {
  text-align: right !important;
}

/* RTL support starts */

html.rtl {

  .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-icon {
    margin-left: 0.5rem;
    margin-right: 0;
  }

  .p-fluid .p-multiselect {
    flex-direction: unset !important;
  }

  .p-dropdown .p-dropdown-clear-icon {
    color: #6c757d;
    left: 3rem;
    right: unset;
  }

  .grid {
    direction: rtl;
  }

  .navButtonsLocal .grid {
    direction: ltr;
  }

  .navButtonsLocal .grid .navButtonsLocationOne {
    text-align: right !important;
  }

  .navButtonsLocal .grid .navButtonsLocationTwo {
    text-align: left !important;
  }

  .localeIconWrapper {
    position: relative;
    width: 80px;
    display: flex;
    justify-content: space-around;
  }

  .localeIconWrapper i.mr-4.ml-4.pi.pi-moon {
    margin-left: 0 !important;
  }

  .p-component {
    direction: rtl;
  }

  .p-button .p-button-icon-left {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .navButtonsStyle .grid {
    display: flex;
    flex-direction: row-reverse;
  }

  .navButtonsStyle .grid .col-6 {
    text-align: left;
  }

  .card.navigation span.p-button-icon.p-c.p-button-icon-left.pi.pi-angle-right {
    rotate: 180deg;
  }

  .navButtonsStyle span.p-button-icon.p-c.p-button-icon-left.pi.pi-angle-right {
    rotate: 180deg;
  }

  .col-lg-12.col-sm-12 .grid {
    display: flex;
    flex-direction: row-reverse;
  }

  .col-lg-12.col-sm-12 .grid .col-6 {
    text-align: left;
  }

  .field-checkbox.additionalQuestLabel {
    margin-right: 30px;
  }

  .p-button .p-badge {
    margin-right: 0.5rem;
  }

  .card.circleNavWrapper i.pi.pi-info.p-2 {
    margin-left: 0.5rem;
  }

  .p-fluid .p-dropdown {
    display: flex;
    /*flex-direction: row-reverse;*/
  }

  .card.circleNavWrapper .card.warningBtnWrapper {
    position: absolute;
    top: 32%;
    right: 86%;
  }

  .card.warningBtnWrapper span.p-button-icon.p-c.p-button-icon-left.pi.pi-exclamation-triangle {
    rotate: none;
  }

  .buttonsHomeWrap button.p-button.p-component.p-button-raised {
    margin-left: 2%;
  }

  .useCaseHeader {
    text-align: left;
  }

  .p-paginator .p-dropdown .p-dropdown-label {
    padding-left: 0;
    padding-right: 10px;
  }

  .col-lg-12.col-sm-12 button.p-button.p-component.mt-2.p-button-secondary span.p-button-icon.p-c.p-button-icon-left.pi.pi-angle-left {
    rotate: 180deg;
  }

  .grid.home button.p-button.p-component.mr-3.p-button-raised {
    margin-left: 2%;
  }

  .grid.home button.p-button.p-component.w-full span.p-button-icon.p-c.p-button-icon-left.pi.pi-user {
    rotate: none;
  }

  .col-lg-12.col-sm-12 button.p-button.p-component.mt-2.p-button-secondary {
    margin-left: 1%;
  }

  .p-checkbox {
    margin-left: 5px;
  }

  .field.checkBoxQuest {
    margin-right: 50%;
    margin-top: -3%;
    width: 100%;
  }

  .localeIconWrapper i.mr-4.ml-4.pi.pi-sun {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .p-message.p-message-info i.pi.pi-info.p-2 {
    margin-left: 5px;
  }

  .p-fluid .p-multiselect {
    display: flex;
    flex-direction: row-reverse;
  }

  .displayLangMenu {
    right: -20px;
  }

  .sectionDiv {
    text-align: center;
  }
  label {
    right: 1rem !important;
  }
}

/* RTL support ends */

/* Media Querries Start KEEP AT THE BOTTOM ALWAYS */

@media screen and (max-width: 375px) {
  .sectionSeparator {
    margin-bottom: 9%;
  }

  div#card\ footer {
    padding: 12px;
    position: absolute;
    width: 100%;
    border-top: 2px solid #c5c5c5;
  }

  div#card\ footer .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    right: -22px;
    bottom: 47px;
    width: max-content;
    top: auto;
  }

  div#card\ footer li.p-menuitem.p-menuitem-active span.p-submenu-icon.pi.pi-angle-down {
    transform: rotate(0deg);
  }
}

@media screen and (width: 414px) {
  div#card\ footer {
    padding: 12px;
    position: absolute;
    width: 100%;
    border-top: 2px solid #c5c5c5;
  }

  div#card\ footer .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    right: -22px;
    bottom: 47px;
    width: max-content;
    top: auto;
  }

  div#card\ footer li.p-menuitem.p-menuitem-active span.p-submenu-icon.pi.pi-angle-down {
    transform: rotate(0deg);
  }
}

@media screen and (max-width: 430px) {
  .useCaseHeader {
    text-align: left;
    margin-left: 10px;
    margin-bottom: 0;
    margin-right: 0;
  }

  .col-12.btnImgTextWrap {
    display: flex;
    flex-direction: column;
  }

  .grid.adminAccWrap {
    display: flex;
    flex-direction: column;
    align-content: center;
  }

  .grid.buttonProcessHistoryWrap {
    margin-bottom: 4%;
  }

  .buttonsHomeWrap {
    display: flex;
    flex-direction: column;
  }

  .buttonsHomeWrap button.p-button.p-component.p-button-raised {
    margin-right: 0;
    margin-bottom: 5%;
  }

  h1.adminAccOverview {
    margin-bottom: 1rem;
  }

  .col-6.text-right.localeWrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .localeIconWrapper {
    width: 59%;
  }

  .grid.home {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    display: flow-root;
  }

  .card.warningBtnWrapper button.p-button.p-component.p-button-warning.select-product-button.validation-button {
    padding: 0.5rem;
    font-size: 12px;
    border-radius: 5px;
  }

  .card.circleNavWrapper .p-steps.p-component {
    width: 100%;
  }

  .card.navigation.arrowWrapper .grid .col-12 .p-steps .p-steps-item {
    font-size: 11px;
  }

  .card.circleNavWrapper .card.warningBtnWrapper {
    position: absolute;
    top: 14rem;
    right: 3rem;
  }
  .p-button {
    font-size: 16px;
  }
  .text-6xl.text-primary.font-bold.mb-3 {
    font-size: 25px !important;
  }
  .loginBtn{
    margin-bottom: 10px !important;
    width: 100%;
    margin-right: 0 !important;
  }
  .home-page img {
    width: 50% !important;
  }
  .tagline {
    font-size: 14px;
  }
  div#copyright-text {
    font-size: 12px;
  }
  .p-float-label label{
    font-size: 14px !important;
    top: -10px !important;
  }
  a.font-medium.no-underline.ml-2.text-blue-500.text-right.cursor-pointer {
    font-size: 16px;
  }
  .outerCaptchaBlockClassFormatted .md\:col {
    margin-bottom: 20px;
  }
  .p-message.p-message-info {
    font-size: 16px;
  }
  .dlTaskWrapper {
    display: flex;
    flex-direction: column;
  }

  .dlTaskWrapper .col-4 {
    width: 100%;
  }

  .dlTaskWrapper .col-6 {
    width: 100%;
  }

  .dlTaskWrapper .col-8 {
    width: 100%;
  }

  .dlTaskWrapper .task-container.step-container.row.col-8 {
    display: flex;
    flex-direction: column;
  }

  .card.circleNavWrapper .card.warningBtnWrapper {
    top: 190px !important;
    right: unset !important;
    left: 30px;
  }

  .p-overlaypanel {
    width: 360px !important;
    left: 21px !important;
  }

  h5 {
    font-size: 16px !important;
  }

  .personTableWrapper input.p-inputtext.p-component {
    width: 4rem;
  }
}

@media screen and (width: 411px) {
  div#card\ footer {
    padding: 12px;
    position: absolute;
    width: 100%;
    border-top: 2px solid #c5c5c5;
  }

  div#card\ footer .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    right: -22px;
    bottom: 47px;
    width: max-content;
    top: auto;
  }

  div#card\ footer li.p-menuitem.p-menuitem-active span.p-submenu-icon.pi.pi-angle-down {
    transform: rotate(0deg);
  }
}


@media screen and (max-width: 768px) {
  .sectionSeparator {
    margin-bottom: 9%;
  }

  .col-12.md\:col-6.order2 {
    order: 2;
  }

  .col-12.md\:col-6.center {
    width: 125%;
  }

  .col-12.md\:col-6 {
    width: 100%;
  }

  h5 {
    margin-top: 5px;
  }

  .pWithShadow {
    margin-top: 0;
  }

  .card.Date h5 {
    width: 47%;
  }

  .xl\:col-3.home {
    width: 50%;
  }

  .col-12.xl\:col-6.order2 img {
    width: 100%;
  }
}

@media screen and (max-width: 769px) {
  .p-picklist {
    flex-direction: column;
  }

  .p-picklist .p-picklist-buttons {
    padding: 1.25rem;
    flex-direction: row;
  }

  .p-picklist .p-picklist-buttons .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }

  .p-picklist .p-picklist-buttons .p-button:last-child {
    margin-right: 0;
  }

  .p-picklist .p-picklist-transfer-buttons .pi-angle-right:before {
    content: "\e930";
  }

  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-right:before {
    content: "\e92c";
  }

  .p-picklist .p-picklist-transfer-buttons .pi-angle-left:before {
    content: "\e933";
  }

  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-left:before {
    content: "\e92f";
  }
}

@media screen and (max-width: 1024px) {
  .xl\:col-3.home {
    width: 50%;
  }

  .col-12.xl\:col-6.order2 img {
    width: 70%;
    margin-left: 15%;
  }

  .pWithShadow {
    margin-top: 0;
  }

  .col-12.xl\:col-6.order2 img {
    width: 91%;
    margin-left: 6%;
  }
}

@media screen and (min-width: 768px) {
  div#card\ footer .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: auto;
    z-index: 51;
    width: max-content;
    right: -57px;
    bottom: 50px;
  }

  div#card\ footer li.p-menuitem.p-menuitem-active span.p-submenu-icon.pi.pi-angle-down {
    transform: rotate(0deg);
  }

  .xl\:col-9.menuFooter {
    margin-left: auto;
  }
}

@media screen and (max-width: 769px) {
  .home-page img {
    width: 100%;
  }

  .sectionSeparator {
    margin-bottom: 9%;
  }

  .p-datepicker table th,
  .p-datepicker table td {
    padding: 0;
  }
}

@media screen and (max-width: 769px) {
  .p-orderlist {
    flex-direction: column;
  }

  .p-orderlist .p-orderlist-controls {
    padding: 1.25rem;
    flex-direction: row;
  }

  .p-orderlist .p-orderlist-controls .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }

  .p-orderlist .p-orderlist-controls .p-button:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 960px) {
  .home-page img {
    width: 100%;
  }

  .sectionSeparator {
    margin-bottom: 9%;
  }

  .p-menubar {
    position: relative;
  }

  .p-menubar .p-menubar-button {
    display: flex;
    width: 2rem;
    height: 2rem;
    color: rgb(85, 73, 148);
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }

  .p-menubar .p-menubar-button:hover {
    color: #495057;
    background: rgb(85, 73, 148)26;
  }

  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem rgb(85, 73, 148)26;
  }

  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    width: 100%;
  }

  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }

  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }

  .p-menubar .p-menubar-root-list>.p-menuitem {
    width: 100%;
    position: static;
  }

  .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link {
    padding: 0.5rem 1rem;
    color: rgb(85, 73, 148);
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }

  .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-text {
    color: rgb(85, 73, 148);
  }

  .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-menuitem-icon {
    color: rgb(85, 73, 148);
    margin-right: 0.5rem;
  }

  .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link .p-submenu-icon {
    color: rgb(85, 73, 148);
  }

  .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover {
    background: rgb(85, 73, 148)26;
  }

  .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: rgb(85, 73, 148);
  }

  .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: rgb(85, 73, 148);
  }

  .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: rgb(85, 73, 148);
  }

  .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    /* box-shadow: inset 0 0 0 0.15rem rgb(85, 73, 148); */
  }

  .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-link>.p-submenu-icon {
    margin-left: auto;
    transition: transform 0.2s;
  }

  .p-menubar .p-menubar-root-list>.p-menuitem.p-menuitem-active>.p-menuitem-link>.p-submenu-icon {
    transform: rotate(-180deg);
  }

  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }

  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.2s;
    transform: rotate(90deg);
  }

  .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active>.p-menuitem-link>.p-submenu-icon {
    transform: rotate(-90deg);
  }

  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }

  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 2.25rem;
  }

  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 3.75rem;
  }

  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 5.25rem;
  }

  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 6.75rem;
  }

  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 8.25rem;
  }

  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}

@media (max-width: 585px) {
  .wizardu {
    height: auto !important;
  }

  .presentation {
    height: 65px;
  }

  span.round-tab {
    font-size: 16px;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  .wizardu .elements>div a {
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin-left: -25px;
  }

  .connecting-line {
    top: 40%;
  }

  span.round-tab svg {
    top: 0rem;
  }
}

@media screen and (max-width: 640px) {
  .sectionSeparator {
    margin-bottom: 9%;
  }

  .home-page img {
    width: 100%;
  }

  .steps-demo .p-steps {
    height: 350px;
  }

  .steps-demo .p-steps>ul {
    flex-direction: column;
    height: 100%;
  }

  .steps-demo .p-steps>ul .p-steps-item {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: center;
  }

  .steps-demo .p-steps>ul .p-steps-item:before {
    position: static;
    left: auto;
    top: auto;
    margin-top: 0;
    border-left: 1px solid var(--surface-d);
    border-top: 0 none;
    width: auto;
    height: 100%;
    margin-left: 1rem;
  }

  .steps-demo .p-steps>ul .p-steps-item .p-menuitem-link {
    flex-direction: row;
    overflow: visible;
  }

  .steps-demo .p-steps>ul .p-steps-item .p-menuitem-link .p-steps-title {
    margin: 0 0.5rem 0;
  }

  .steps-demo .p-steps>ul .p-steps-item:last-child {
    flex-grow: 0;
  }

  .steps-demo .p-steps>ul .p-steps-item:last-child .p-menuitem-link {
    padding: 0;
  }

  .steps-demo .p-steps>ul .p-steps-item:last-child:before {
    display: none;
  }
}

@media screen and (min-width: 2133.33px) {
  .col-12.md\:col-8 {
    min-height: 85vh;
  }
}

@media screen and (min-width: 2400px) {
  .col-12.md\:col-8 {
    min-height: 86.5vh;
  }
}

@media screen and (min-width: 2560px) {
  .col-12.md\:col-8 {
    min-height: 87.5vh;
  }
}

@media screen and (min-width: 2880px) {
  .col-12.md\:col-8 {
    min-height: 88.5vh;
  }
}

#printCredentials {
  visibility: hidden;
}


@media print {
  body {
    visibility: hidden;
  }

  #printCredentials {
    /*position: fixed;*/
    /*top:0;*/
    /*left: 0;*/
    /*height: 100%;*/
    /*width: 100%;*/
    /*z-index: 9999999999;*/
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }
}

/* Media Querries End KEEP AT THE BOTTOM ALWAYS  */